import { Input, Pipe, PipeTransform } from '@angular/core';
import {
  DateFormatType,
  DateManagerService,
} from '@core/services/date-manager/date-manager.service';

@Pipe({
  name: 'xpwTimeFormatter',
})
export class XpwTimeFormatterPipe implements PipeTransform {
  constructor(private dateService: DateManagerService) {}

  transform(date: Date): string {
    return this.dateService.setFormatDate(date, DateFormatType.TIME);
  }
}
