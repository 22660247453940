import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Injector,
  Output,
  EventEmitter,
} from '@angular/core';
import { DateManagerService } from '@core/services/date-manager/date-manager.service';
import { NzStatus } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'xpw-input-time',
  template: `
    <nz-form-item>
      <nz-form-label>{{ label }} </nz-form-label>

      <nz-time-picker
        [(ngModel)]="value"
        nzFormat="HH:mm"
        [nzDisabled]="readonly"
        (ngModelChange)="timeChanged($event)"
        [nzStatus]="status"
        [nzAllowEmpty]="false"
      ></nz-time-picker>
      <ng-content></ng-content>
    </nz-form-item>
  `,
  styles: `
    nz-time-picker {
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./xpw-input-date.component.less'],
})
export class XpwInputTimeComponent {
  @Input() value: any;
  @Input() readonly: boolean = false;
  @Input('label') label: string;
  @Input('hasFeedback') hasFeedback: boolean = true;
  @Input('utcCorrection') utcCorrection: boolean = true;
  @Input() status: NzStatus = '';
  @Output() onChange = new EventEmitter<any>();

  get format(): string {
    return 'HH:mm';
  }

  constructor(private dateService: DateManagerService) {
    // super(injector);
  }

  timeChanged(value: any) {
    this.onChange.emit(value);
    // if (this.utcCorrection) {
    //   this.onChange(this.dateService.getOnlyTime(value));
    // } else {
    //   this.onChange(value);
    // }
  }

  // override writeValue(value: any): void {
  //   if (value) {
  //     super.writeValue(value);
  //   }
  // }
}
