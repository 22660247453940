import { createReducer, on } from '@ngrx/store';
import {
  getCurrentUserData,
  loginFailure,
  getCurrentUserDataSuccess,
  logout,
  setAccessObjects,
  getCurrentOrganizationsSuccess,
  setCurrentOrganization,
  getCurrentOrganizations,
  removeLoading,
  getAccessObjects,
  updateAccessObjects,
} from './current-user.actions';
import { initialState } from './current-user.state';
import { IMeterGroupView } from '@features/meter-group/store/meter-group.interface';

export const currentUserReducer = createReducer(
  initialState,
  on(
    getCurrentUserData,
    (state) =>
      (state = {
        ...state,
        loginData: {
          loading: true,
          isLoggedIn: false,
          message: 'Please wait a moment while we load your personal account',
          organizations: state.loginData.organizations,
        },
      }),
  ),
  on(
    getAccessObjects,
    (state) =>
      (state = {
        ...state,
        loginData: {
          loading: true,
          isLoggedIn: false,
          message: 'fetching access objects...',
          organizations: state.loginData.organizations,
        },
      }),
  ),
  on(
    loginFailure,
    (state) =>
      (state = {
        ...initialState,
        loginData: {
          loading: true,
          isLoggedIn: false,
          message: 'Redirecting to login page...',
          organizations: state.loginData.organizations,
        },
      }),
  ),
  on(
    getCurrentUserDataSuccess,
    (state, { currentUser }) =>
      (state = {
        ...state,
        ...currentUser,
        loginData: {
          loading: false,
          isLoggedIn: true,
          message: 'done... ',
          organizations: state.loginData.organizations,
        },
      }),
  ),
  on(
    setAccessObjects,
    (state, { accessObjects }) =>
      (state = {
        ...state,
        accessObjects: {
          departments: accessObjects.departments,
          meterGroups: accessObjects.meterGroups,
        },
      }),
  ),
  on(updateAccessObjects, (state, { departments, meterGroups }) => {
    // Helper function to recursively map meter groups with children
    function mapMeterGroups(
      groups: IMeterGroupView[],
    ): { name: string; uid: string }[] {
      let result: { name: string; uid: string }[] = [];

      groups.forEach((group) => {
        // Add the current group to the result
        result.push({
          name: group.meterGroupName,
          uid: group.meterGroupUID,
        });

        // Recursively add child groups to the result
        if (group.children && group.children.length > 0) {
          result = result.concat(mapMeterGroups(group.children));
        }
      });

      return result;
    }

    return {
      ...state,
      accessObjects: {
        ...state.accessObjects,
        departments: departments
          ? departments.map((department) => ({
              name: department.departmentName,
              uid: department.departmentUID,
            }))
          : state.accessObjects.departments,
        meterGroups: meterGroups
          ? mapMeterGroups(meterGroups)
          : state.accessObjects.meterGroups,
      },
    };
  }),
  on(
    logout,
    (state) =>
      (state = {
        ...initialState,
        loginData: { isLoggedIn: false, message: 'loging out... ' },
      }),
  ),
  on(
    getCurrentOrganizations,
    (state) =>
      (state = {
        ...state,
        loginData: {
          isLoggedIn: true,
          message: 'fetching organizations... ',
          organizations: [],
          loading: true,
        },
      }),
  ),
  on(
    getCurrentOrganizationsSuccess,
    (state, { organizations }) =>
      (state = {
        ...state,
        loginData: {
          isLoggedIn: true,
          message: 'done... ',
          organizations: organizations,
          loading: false,
        },
      }),
  ),
  on(
    setCurrentOrganization,
    (state, { organizationUID, departmentUID }) =>
      (state = {
        ...state,
        loginData: {
          isLoggedIn: false,
          message: 'changing organization... ',
          organizations: state.loginData.organizations,
        },
      }),
  ),
  on(
    removeLoading,
    (state) =>
      (state = {
        ...state,
        loginData: {
          isLoggedIn: true,
          loading: false,
        },
      }),
  ),
  /* on(changeDefaultPage, (state,{pageUrl}) => (state = {...state, defaultPage: pageUrl})) */
);
