import {
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal,
} from '@angular/core';
import { FilterItem } from './xpw-abstract-table-list.component';
import { ErrorKeyLabels } from '@shared/utility/errors-keys-msg';
import { EnumTitleType } from '@shared/utility/global-enums-titles';

interface FilterDataWithTitle extends FilterItem {
  computedTitle: string;
  originalItem: FilterItem;
}

@Component({
  selector: 'xpw-table-header',
  template: `
    <div class="filter-container">
      <div class="filter-by-container">
        <span i18n class="filter-by-title">{{ title() }}</span>
        <div class="filter-type-container">
          <span
            class="filter-type"
            *ngFor="let filtertype of processedFilters() | keyvalue"
          >
            <span class="type-title">{{ stringKey[filtertype.key] }}: </span>
            <span class="filter-type-list">
              <span
                *ngFor="let fData of filtertype.value"
                class="filter-type-list-item"
              >
                <xpw-tag-closable
                  [label]="fData.computedTitle"
                  [tagMode]="fData.tagMode"
                  nz-tooltip
                  [nzTooltipTitle]="fData.tooltipText"
                  (onCloseClick)="propertyRemoved(fData)"
                />
              </span>
            </span>
          </span>
        </div>
      </div>
      <xpw-button
        (click)="clearFilters()"
        type="text"
        i18n="@@Clear"
        class="clear-button"
      >
        <xpw-icon icon="clear" />
        Clear All
      </xpw-button>
    </div>
  `,
  styleUrls: ['./xpw-table-header.component.less'],
})
export class XpwTableHeaderComponent {
  private filterData = signal<IFilterDataForHeader>({});
  private titles = signal<{ [key: string]: EnumTitleType }>({});

  @Input() set filterDataForHeader(value: IFilterDataForHeader) {
    console.log('filterDataForHeader', value);
    this.filterData.set(value);
  }

  @Input() set filterTitles(value: { [key: string]: EnumTitleType | any }) {
    this.titles.set(value);
  }
  title = signal<string>('Filtered By');
  @Input() set optionalTitle(value: string) {
    this.title.set(value);
  }

  @Output() filterPropertyRemoved = new EventEmitter<FilterItem>();
  @Output() clearAllFilters = new EventEmitter<void>();

  stringKey = ErrorKeyLabels;

  processedFilters = computed(() => {
    const result: { [key: string]: FilterDataWithTitle[] } = {};

    Object.entries(this.filterData()).forEach(([key, items]) => {
      // if (!Array.isArray(items)) return;
      result[key] = items.map((item) => ({
        ...item,
        computedTitle: this.computeTitle(item),
        originalItem: item, // Store the original item reference
        tagMode: 'closeable',
      }));
    });

    const filteredResults: { [key: string]: FilterDataWithTitle[] } =
      Object.entries(result).reduce((acc, [key, items]) => {
        // enter first 5 items of key, and add one last tag with number of remaining items with a plus +
        const firstFiveItems = items.slice(0, 5);
        const remainingItems = items.slice(5);
        if (remainingItems.length > 0) {
          acc[key] = [
            ...firstFiveItems,
            {
              label: remainingItems.length + ' +',
              value: '',
              type: '',
              computedTitle: remainingItems.length + ' +',
              originalItem: {
                label: remainingItems.length + ' +',
                value: '',
                type: '',
              },
              tagMode: 'default',
            },
          ];
        } else {
          acc[key] = firstFiveItems;
        }
        return acc;
      }, {});

    return filteredResults;
  });

  private computeTitle(fData: any): string {
    return this.titles()[fData.type]
      ? this.titles()[fData.type][fData.value]
      : fData.label;
  }

  clearFilters() {
    this.clearAllFilters.emit();
  }

  propertyRemoved(
    removedProperty: FilterDataWithTitle & { originalItem: FilterItem },
  ) {
    this.filterPropertyRemoved.emit(removedProperty.originalItem);
  }
}

export interface IFilterDataForHeader {
  [key: string]: { label: string; value: string; type: string }[];
}
