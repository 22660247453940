import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser, AccessObjectType } from '@features/user/store/user.interface';

import { IPendingUser } from '@features/user/store/user.interface';
import { environment } from 'src/environments/environment';

import {
  IActUser,
  IGetStatusResponse,
} from '@features/act-user/act-user-store/act-user.interface';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  apiBaseUrl = '';
  endpoint = 'registration'; // Name form API

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getUserInvitationStatus(
    guidUser: string,
  ): Observable<IGetStatusResponse> | null {
    if (guidUser)
      return this.http.get<IGetStatusResponse>(
        `${this.apiBaseUrl}${this.endpoint}/invitationStatus`,
        {
          params: { Param: guidUser },
        },
      );
    else {
      return null;
    }
  }

  sentOtcUserRegistration(
    userUId: string = null,
    mobilePhone: string,
    verifyViaCall: boolean,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/sendOtp4UserRegistration`,
      {
        userUID: userUId,
        mobilePhone: mobilePhone,
        verifyViaCall: verifyViaCall,
      },
    );
  }

  completeRegistration(user: IActUser): Observable<any> {
    let bodyForSending = {
      userUId: user.userUId,
      firstName: user.firstName,
      lastName: user.lastName,
      mobilePhone: user.mobilePhone,
      languageID: user.languageID,
      loginIdentifierTypeID: user.loginIdentifierTypeID,
      userName: user.userName,
      password: user.password,
      otp: user.otp,
    };
    // console.log(bodyForSending);
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/completeRegistration`,
      bodyForSending,
    );
  }
}
