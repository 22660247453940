import {
  IMeterGroup,
  IMeterGroupView,
  ISelectedMeterGroup,
} from './meter-group.interface';

export interface IMeterGroupState {
  meterGroups: IMeterGroupView[];
  filteredMetersGroup: IMeterGroupView[];
  selectedMeterGroup: ISelectedMeterGroup;
  lastGroupUpdated: IMeterGroup | null;
  virtualMeterGroupsList: IMeterGroupView[];
  filteredVirtualMeterGroupsList: IMeterGroupView[];
  drawersState: {
    editCreateMeterGroupDrawer: boolean;
    addMetersDrawer: boolean;
  };
  ValidationErrors: any;
  newMeterGroup: boolean;
  searchText: string;
  searchResultCount: number | null;
  currentStepOrTab: number;
  saveButton: saveButton;
}

export enum saveButton {
  generalInfo,
  removeMeters,
  addMeters,
}
export const initialMeterGroupState: IMeterGroupState = {
  meterGroups: [],
  filteredMetersGroup: [],
  virtualMeterGroupsList: [],
  filteredVirtualMeterGroupsList: [],
  selectedMeterGroup: {
    meterGroupUID: '',
    meterGroupName: '',
    organizationUID: '',
    metersCount: 0,
    parentMeterGroupUID: null,
    meterUIDsToAdd: [],
    meterUIDsToRemove: [],
    meterUpdate: '',
  },
  lastGroupUpdated: null,
  drawersState: {
    editCreateMeterGroupDrawer: false,
    addMetersDrawer: false,
  },
  ValidationErrors: null,
  newMeterGroup: false,
  searchText: '',
  searchResultCount: null,
  currentStepOrTab: 0,
  saveButton: saveButton.generalInfo,
};
