<div class="container main-navigation">
  <div class="sidebar-logo">
    <img
      src="./assets/logo/xpw-full-logo.svg"
      style="height: 40px"
      alt="logo"
      *ngIf="!isCollapsed"
    />
    <img
      src="./assets/logo/xpw-icon-logo.svg"
      alt="logo"
      style="width: 40px"
      *ngIf="isCollapsed"
    />

    <span class="header-trigger anticon" (click)="changeCollapse()">
      <span *ngIf="isCollapsed">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="CaretRight">
            <path
              id="Vector"
              d="M8.38828 5.78337L3.92578 1.93493C3.75937 1.79196 3.51562 1.92087 3.51562 2.15173V9.84861C3.51562 10.0795 3.75937 10.2084 3.92578 10.0654L8.38828 6.21696C8.51602 6.10681 8.51602 5.89353 8.38828 5.78337Z"
              fill="black"
              fill-opacity="0.45"
            />
          </g>
        </svg>
      </span>
      <span *ngIf="!isCollapsed">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="CaretLeft">
            <path
              id="Vector"
              d="M3.61144 5.78337L8.07394 1.93493C8.24035 1.79196 8.4841 1.92087 8.4841 2.15173V9.84861C8.4841 10.0795 8.24035 10.2084 8.07394 10.0654L3.61144 6.21696C3.48371 6.10681 3.48371 5.89353 3.61144 5.78337Z"
              fill="black"
              fill-opacity="0.45"
            />
          </g>
        </svg>
      </span>
    </span>
  </div>

  <div class="border-line"></div>

  <ul
    style="overflow-y: auto; overflow-x: hidden"
    nz-menu
    nzTheme="light"
    nzMode="inline"
    *ngIf="isLoggedIn()"
    [nzInlineCollapsed]="isCollapsed"
  >
    <ng-template ngFor let-item [ngForOf]="mainNavigation">
      <li
        nz-submenu
        nzTitle="{{ item.title }}"
        nzIcon="nav-icons:{{ item.icon }}"
        nzOpen="{{ item.open }}"
        (nzOpenChange)="onItemNavOpenChange(item)"
        *ngIf="shouldView(item)"
      >
        <ul>
          <ng-template ngFor let-grand [ngForOf]="item.children">
            <li
              nz-menu-item
              nzIcon="nav-icons:ellipse"
              (click)="navigate(grand.url)"
              [nzSelected]="grand.selected"
              *ngIf="shouldView(grand)"
              nzDisabled="{{ grand.disabled == true }}"
            >
              <a>
                {{ grand.title }}
              </a>
            </li>
          </ng-template>
        </ul>
      </li>
    </ng-template>
  </ul>

  <section class="bottom-items">
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed"></ul>
    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nzDisabled nz-menu-item nz-tooltip nzTooltipPlacement="right">
        <span nz-icon nzType="nav-icons:info"></span>
        <span>About</span>
      </li>
    </ul>

    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nzDisabled nz-menu-item nz-tooltip nzTooltipPlacement="right">
        <span nzDisabled nz-icon nzType="nav-icons:contact-us"></span>
        <span>Contact Us</span>
      </li>
    </ul>
    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nzDisabled nz-menu-item nz-tooltip nzTooltipPlacement="right">
        <span nz-icon nzType="nav-icons:help"></span>
        <span>Support</span>
      </li>
    </ul>
    <!-- <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" (click)="logout()">
        <span nz-icon nzType="nav-icons:help"></span>
        <span>Logout</span>
      </li>
    </ul> -->
  </section>
</div>
