import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  effect,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { BaseDrawerTemplateComponent } from '@core/services/popup/base-drawer-template.component';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  IChannel,
  IChannelConfigurations,
  UsageTypeID,
  UsageTypeIDTitle,
} from '@features/organizations/store/organizations.interface';
import {
  addChannelConfigurations,
  editChannelConfigurations,
} from '@features/organizations/store/organizations.actions';
import {
  selectChannelConfigurations,
  selectErrors,
  selectSelectedChannelConfigurations,
} from '@features/organizations/store/organizations.selector';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { CustomVlidators } from '@shared/utility/custom-validators';
import { ConfirmDialogService } from '@core/services/confirm-dialog/confirm-dialog.service';
import { ErrorKey, ErrorKeyLabels } from '@shared/utility/errors-keys-msg';

@Component({
  selector: 'app-channel-configuration-popup',
  template: `<ng-template #componentTemplate let-drawerRef="drawerRef">
    <form nz-form [formGroup]="xpwForm" [nzLayout]="'vertical'">
      <div class="_form-content">
        <section>
          <xpw-form-input-text
            formControlName="channelConfigurationName"
            label="Name"
            placeholder="Name"
          />
        </section>
        <section>
          <ng-container formArrayName="mappings">
            <nz-table #smallTable nzSize="small" [nzShowPagination]="false">
              <thead>
                <tr>
                  <th style="width: 10%;">#</th>
                  <th style="width: 50%;">Channel</th>
                  <th style="width: 30%;">Usage Type</th>
                  <th style="width: 10%;"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="let mapping of mappingsArray.controls; let i = index"
                  [formGroupName]="i"
                >
                  <tr>
                    <td style="width: 10%;">{{ i + 1 }}</td>
                    <td>
                      <xpw-form-input-number
                        formControlName="channel"
                        placeholder="Channel"
                        style="height:35px;display:block;"
                      ></xpw-form-input-number>
                    </td>
                    <td style="max-width:30%;">
                      <nz-form-control
                        [nzValidateStatus]="mapping.get('usageTypeID')"
                        [nzErrorTip]="'Please select a usage type'"
                      >
                        <div
                          style="width: 160px; max-width: 100%;height:35px;display:block;"
                        >
                          <xpw-form-select
                            [hasFeedback]="false"
                            formControlName="usageTypeID"
                            placeholder="Select Usage Type"
                            [options]="_usageTypeOptions"
                          ></xpw-form-select>
                        </div>
                      </nz-form-control>
                    </td>
                    <td style="width:10%;">
                      <xpw-button
                        nz-button
                        type="link"
                        (click)="removeMapping(i); xpwForm.markAsDirty()"
                      >
                        <xpw-icon icon="delete"></xpw-icon>
                      </xpw-button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </nz-table>
            <ng-container
              *ngIf="xpwForm.get('mappings')?.errors"
              style="color:red;"
            >
              <div>
                <span style="color:red;">
                  {{ errorMessage }}
                </span>
              </div>
            </ng-container>
          </ng-container>
          <xpw-button
            [disabled]="addMappingsDisabled"
            nz-button
            type="default"
            (click)="addMapping()"
            >Add Mapping</xpw-button
          >
        </section>
      </div>
      <div class="_controls">
        <xpw-button
          nz-button
          (click)="saveChannelConfiguration()"
          type="primary"
          [disabled]="
            !xpwForm.valid ||
            xpwForm.pristine ||
            xpwForm.get('mappings').value.length === 0
          "
          i18n="@@saveChanges"
        >
          <xpw-icon icon="save" />
          Save Changes
        </xpw-button>
        <xpw-button
          (click)="closeDrawer(drawerRef)"
          nz-button
          type="text"
          i18n="@@cancel"
        >
          Cancel
        </xpw-button>
      </div>
    </form>
  </ng-template> `,
  styleUrls: ['./organization-channel-configuration-popup.component.less'],
})
export class XpwChannelConfigurationPopupComponent
  extends BaseDrawerTemplateComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('myTemplate') template: TemplateRef<any>;
  errorKey = ErrorKey;
  subscriptions = new Subscription();
  _usageTypeOptions: any[] = [];
  addMappingsDisabled: boolean = false;
  listChannelConfigurationNames: string[] = [];
  mappingsArray: FormArray;
  validationErrors$ = this.store.selectSignal(selectErrors);
  errorMessage: string = '';
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private confirmDialogService: ConfirmDialogService,
  ) {
    super();
    effect(() => {
      const errors = this.validationErrors$();
      if (errors) {
        this.handleServerErrors(errors, () => {
          if (this.xpwForm.get('mappings')?.errors) {
            this.errorMessage =
              ErrorKeyLabels.CannotRemoveMappingIfChannelsAreInUseByMeterReplacement;
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this._usageTypeOptions = GlobalFunctions.convertEnumToOptions(
      UsageTypeID,
      UsageTypeIDTitle,
    );
    this.xpwForm = this.initForm();
    // Initialize the mappingsArray reference once
    this.mappingsArray = this.xpwForm.get('mappings') as FormArray;

    this.subscriptions.add(
      this.store
        .select(selectSelectedChannelConfigurations)
        .subscribe((channelConfiguration) => {
          if (channelConfiguration) {
            this.updateForm(channelConfiguration);
          }
        }),
    );

    this.subscriptions.add(
      this.store
        .select(selectChannelConfigurations)
        .subscribe((channelConfigurationList) => {
          this.listChannelConfigurationNames =
            channelConfigurationList.map(
              (value) => value.channelConfigurationName,
            ) ?? [];
        }),
    );

    // for validation duplicate names
    this.xpwForm
      .get('channelConfigurationName')
      .valueChanges.subscribe((value) => {
        const isDuplicate = this.listChannelConfigurationNames.includes(value);
        if (isDuplicate) {
          this.xpwForm
            .get('channelConfigurationName')
            .setErrors({ duplicateName: true });
        } else {
          this.xpwForm.get('channelConfigurationName').setErrors(null);
        }
      });

    // for validation mapping
    this.subscriptions.add(
      this.xpwForm.get('mappings').valueChanges.subscribe((value) => {
        this._usageTypeOptions = GlobalFunctions.convertEnumToOptions(
          UsageTypeID,
          UsageTypeIDTitle,
          value
            .filter((item) => item.usageTypeID !== null)
            .map((item) => item.usageTypeID as number),
        );

        // can't add channels if empty list
        this.addMappingsDisabled =
          this._usageTypeOptions.length < 1 ? true : false;

        // Create a map to track channels and their occurrences
        const channelMap = new Map<string, number[]>();

        // First pass: collect channel occurrences
        value.forEach((item, index) => {
          if (!item.channel) return;
          const channelKey = item.channel.toString().trim();
          if (channelKey === '') return;

          if (!channelMap.has(channelKey)) {
            channelMap.set(channelKey, [index]);
          } else {
            channelMap.get(channelKey).push(index);
          }
        });

        // Second pass: set or clear errors for each channel control
        value.forEach((item, index) => {
          const channelControl = this.xpwForm.get(`mappings.${index}.channel`);
          if (!channelControl) return;

          // Clear previous errors to start fresh
          channelControl.setErrors(null);

          // Check for required validation
          if (item.channel === null || item.channel === '') {
            channelControl.setErrors({ required: true });
            return;
          }

          // Check for duplicate channels
          const channelKey = item.channel.toString().trim();
          const isDuplicate = channelMap.get(channelKey)?.length > 1;

          // Check for min/max validation
          const minValidator =
            channelControl.validator?.(channelControl)?.['min'];
          const maxValidator =
            channelControl.validator?.(channelControl)?.['max'];
          const isOutOfRange = minValidator?.min || maxValidator?.max;

          // Set appropriate errors
          if (isDuplicate && isOutOfRange) {
            channelControl.setErrors({
              notUniqueChannel: true,
              min: minValidator?.min,
              max: maxValidator?.max,
            });
          } else if (isDuplicate) {
            channelControl.setErrors({ notUniqueChannel: true });
          } else if (isOutOfRange) {
            channelControl.setErrors({
              min: minValidator?.min,
              max: maxValidator?.max,
            });
          }
        });
      }),
    );
  }

  ngAfterViewInit() {}

  initForm(): FormGroup {
    return this.fb.group({
      channelConfigurationUID: [null],
      channelConfigurationName: ['', Validators.required],
      mappings: this.fb.array([]),
    });
  }

  createMapping(mapping?: IChannel): FormGroup {
    // order the mappings by index
    const index = mapping ? mapping.index : this.mappingsArray.length + 1;
    return this.fb.group({
      usageTypeID: [mapping ? mapping.usageTypeID : null, Validators.required],
      channel: [
        mapping ? mapping.channel : null,
        [Validators.min(-32768), Validators.max(32767), Validators.required],
      ],
      index: [index],
      channelConfigurationMappingUID: [
        mapping ? mapping.channelConfigurationMappingUID : null,
      ],
      isErtDefined: [mapping ? mapping.isErtDefined : false],
    });
  }

  addMapping(): void {
    this.mappingsArray.push(this.createMapping());
  }

  removeMapping(index: number): void {
    // check if mappingArray on index has isErtDefined = false
    if (this.mappingsArray.at(index).get('isErtDefined')?.value) {
      this.confirmDialogService.showConfirmModal({
        title: $localize`Action Prohibited`,
        content: $localize`The channel you are trying to delete has a defined ERT ID. 
        <br />
        Deleting this channel would cause inconsistencies in the system.
        <br />
         To proceed, please remove the ERT ID definition before deleting the channel.`,
        showOkButton: false,
        cancelText: 'Ok',
        auditCommentsVisible: false,
      });
    } else {
      this.mappingsArray.removeAt(index);
      // Update indices after removal
      this.updateIndices();
    }
  }

  // New method to update indices
  private updateIndices(): void {
    this.mappingsArray.controls.forEach((control, newIndex) => {
      control.get('index')?.setValue(newIndex + 1);
    });
  }

  updateForm(value: IChannelConfigurations): void {
    this.xpwForm.patchValue({
      channelConfigurationUID: value.channelConfigurationUID,
      channelConfigurationName: value.channelConfigurationName,
    });

    this.mappingsArray.clear();
    value.mappings.forEach((mapping) => {
      this.mappingsArray.push(this.createMapping(mapping));
    });
  }

  saveChannelConfiguration() {
    let hasDirtyMapping = false;
    // Iterate over all controls to check if any mapping has isErtDefined = false and is dirty
    this.mappingsArray.controls.forEach((control) => {
      if (control.get('isErtDefined')?.value && control.dirty) {
        hasDirtyMapping = true;
        this.confirmDialogService.showConfirmModal({
          title: 'Warning',
          content: $localize`You are changing the definition of the configuration channel. 
                    This action will automatically update the data type for all meters using this configuration during the current replacement period.`,
          okText: 'Save Changes',
          cancelText: 'Cancel',
          okButtonIsRed: true,
          handleOk: () => {
            this.submit();
          },
          handleCancel: () => {
            this.confirmDialogService.CloseDialog();
          },
        });
      }
    });
    // If no dirty mapping was found, proceed with submission directly
    if (!hasDirtyMapping) {
      this.submit();
    }
  }
  submit() {
    if (this.xpwForm.get('channelConfigurationUID').value === null) {
      this.store.dispatch(
        addChannelConfigurations({ channelConfiguration: this.xpwForm.value }),
      );
    } else {
      this.store.dispatch(
        editChannelConfigurations({ channelConfiguration: this.xpwForm.value }),
      );
    }
  }
  trackByIndex(index: number): number {
    return index;
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
