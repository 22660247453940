/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment, DD_SESSION_REPLAY_RECORDING } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: environment.datadog.applicationId,
  clientToken: environment.datadog.clientToken,
  site: environment.datadog.site,
  service: environment.datadog.service,
  env: environment.datadog.env,
  sessionSampleRate: environment.datadog.sessionSampleRate,
  sessionReplaySampleRate: environment.datadog.sessionReplaySampleRate,
  defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel,
  trackUserInteractions: environment.datadog.trackUserInteractions,
});
if (environment.datadog.SessionReplayRecording === DD_SESSION_REPLAY_RECORDING.FORCE) {
  datadogRum.startSessionReplayRecording({ force: true });
}

if (environment.production) {
  enableProdMode();
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('ngsw-worker.js');
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
