import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  Injector,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { differenceInCalendarDays } from 'date-fns';
import { DateManagerService } from '@core/services/date-manager/date-manager.service';
import { NzStatus } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'xpw-input-date',
  template: `
    <nz-form-item>
      <nz-form-label>{{ label }} </nz-form-label>
      <nz-form-control [nzErrorTip]="errorMessage" [nzValidateStatus]="status">
        <span class="flex flex-row gap">
          <nz-date-picker
            ngDefaultControl
            [nzFormat]="dateFormat"
            [nzStatus]="status"
            [(ngModel)]="value"
            (ngModelChange)="calanderChange($event)"
            [nzDisabled]="readonly"
            [nzDisabledDate]="disabledDate"
            [nzAllowClear]="false"
          ></nz-date-picker>
          <ng-content></ng-content>
        </span>
      </nz-form-control>
    </nz-form-item>
  `,
  styleUrls: ['./xpw-input-date.component.less'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class XpwInputDateComponent {
  @Input() value: Date;
  @Input() readonly: boolean = false;
  @Input('label') label: string;
  @Input() showDateTime: boolean = false;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() allowFutureDate: boolean = true;
  @Input() status: NzStatus = '';
  @Input() errorMessage: string = $localize`Please enter a valid date`;

  @Output() dateChange = new EventEmitter<Date>();

  dateFormat: string;
  constructor(
    private dateService: DateManagerService,
    injector: Injector,
  ) {
    this.dateFormat = this.format;
  }
  private today = this.dateService.getOnlyDate(new Date());

  get format(): string {
    console.log(' get format()', this.dateService.dateTimePickerFormat);
    if (this.showDateTime) {
      return this.dateService.DateTimePickerFormat;
    } else {
      return this.dateService.DatePickerFormat;
    }
  }

  calanderChange = (value: any): void => {
    const onlyDate = this.dateService.getOnlyDate(value);
    this.dateChange.emit(onlyDate);
  };

  // Can not select days before today and today
  disabledDate = (current: Date): boolean => {
    return (
      (this.maxDate && current > this.maxDate) ||
      (this.minDate && current < this.minDate) ||
      (!this.allowFutureDate && current > this.today)
    );
  };
}
