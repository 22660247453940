import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorKey } from './errors-keys-msg';

export class CustomVlidators {
  // validate email
  static validateEmail(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          return resolve(null);
        }

        const emailRegexForValidation = /^[\w\.-]+@[\w\.-]+\.\w+$/;

        let isValid = emailRegexForValidation.test(control.value);

        return resolve(isValid ? null : { invalid: true });
      });
    };
  }
  static validateDecimal(format: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        if (!control.value) {
          return resolve(null);
        }

        // Create regex for integer part based on the format
        const [integerPart, decimalPart] = format.split('.');

        // Integer part should be 1 to the number of '#'s in the format (e.g., ### allows 1 to 3 digits)
        let integerRegex = `\\d{1,${integerPart.length}}`;

        // Decimal part should be optional, and up to the number of '#'s in the format
        let decimalRegex = decimalPart
          ? `(\\.\\d{1,${decimalPart.length}})?`
          : '';

        // Combine the regex for integer and decimal parts, including optional "-" for negative values
        const decimalRegexForValidation = new RegExp(
          `^-?${integerRegex}${decimalRegex}$`,
        );

        const isValid = decimalRegexForValidation.test(control.value);

        return resolve(isValid ? null : { invalidDecimal: true });
      });
    };
  }

  static numberValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          return resolve(null);
        }
        const value = control.value;
        // Check if value is a string or number
        if (typeof value !== 'string' && typeof value !== 'number') {
          return resolve({ notNumber: true });
        }
        // Convert value to string for regex testing
        const stringValue = value.toString();
        // Regex to match valid decimal numbers (with optional leading + or -)
        const decimalRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
        const isValid = decimalRegex.test(stringValue);
        return resolve(isValid ? null : { notNumber: true });
      });
    };
  }

  // validate parameters
  static validateParams(typeParams: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          return resolve(null);
        }
        if (typeParams === 'url') {
          const urlRegexForValidation =
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$|^(http:\/\/|https:\/\/|localhost|\/\/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})(:[0-9]{1,5})?(\/.*)?$/;
          let isValid = urlRegexForValidation.test(control.value);
          return resolve(isValid ? null : { invalidURL: true });
        }

        return resolve(null);
      });
    };
  }
  // validate decimale number is greater than 0 a lower than 1
  static validateDecimalNumberBetween0And1(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          return resolve(null);
        }
        const isValid = control.value > 0 && control.value <= 1;

        return resolve(
          isValid ? null : { decimalNumberNotBetweenZeroAndOne: true },
        );
      });
    };
  }

  static validateFutureDate(): ValidatorFn {
    // validate if date is in the past
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        const value = control.value;
        if (!value) {
          return resolve(null);
        }
        const date = new Date(value);
        const now = new Date();
        if (date < now) {
          resolve({ notFutureDate: true });
        } else {
          resolve(null);
        }
      });
    };
  }

  static validateDateIsNotFuture(): ValidatorFn {
    // validate if date is in the past
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        const value = control.value;
        if (!value) {
          return resolve(null);
        }
        const date = new Date(value);
        const now = new Date();
        if (date > now) {
          resolve({ cannotBeFutureDate: true });
        } else {
          resolve(null);
        }
      });
    };
  }
  // create customvalidator that check if date is later than a given date
  static validateDateIsLaterThanGivenDate(givenDate: Date): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        const value = control.value;
        if (!value || !givenDate) {
          return resolve(null);
        }
        const date = new Date(value);
        if (date <= givenDate) {
          resolve({ dateNotLaterThanGivenDate: true });
        } else {
          resolve(null);
        }
      });
    };
  }

  static alphabeticValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        let isValid = /^[\p{L} ]*$/u.test(control.value);
        if (isValid) {
          resolve(null); // No error
        } else {
          resolve({ alphabeticCharactersOnly: true });
        }
      });
    };
  }

  static validatePhoneNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const phoneValue = control.value;

        if (!phoneValue) {
          // Return null for empty fields
          return resolve(null);
        }

        // Define regular expressions for valid phone numbers
        const regEx =
          /^(?:\+1\s\d{10}|\+380\s\d{9}|\+44\s\d{10}|\+972\s\d{9}|\+61\s\d{9}|\+64\s\d{8,10}|\+91\s\d{10}|\+49\s\d{10,14}|\+33\s\d{9}|\+39\s\d{9,10}|\+34\s\d{9}|\+81\s\d{10}|\+82\s\d{9,10}|\+86\s\d{11}|\+55\s\d{10,11}|\+52\s\d{10}|\+7\s\d{10}|\+27\s\d{9}|\+234\s\d{10}|\+20\s\d{10}|\+90\s\d{10}|\+966\s\d{9}|\+971\s\d{8,9}|\+65\s\d{8}|\+60\s\d{9,10}|\+62\s\d{10,11}|\+63\s\d{10}|\+66\s\d{9}|\+84\s\d{9,10})$/;

        let isValid = regEx.test(phoneValue);
        if (isValid) {
          resolve(null); // No error
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      });
    };
  }

  static passwordValidator(): ValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        if (!control.value) {
          resolve(null);
        }

        let pswErrors: { [key: string]: boolean } | null = {};
        // Check if password is at least 8 characters long
        if (control.value.length < 8) {
          pswErrors[ErrorKey.pswLength] = true;
        }
        if (control.value.length > 64) {
          pswErrors[ErrorKey.pswMaxLengthExceed] = true;
        }

        if (!/[a-z]/.test(control?.value))
          pswErrors[ErrorKey.hasLowercase] = true;
        if (!/[A-Z]/.test(control?.value))
          pswErrors[ErrorKey.hasUppercase] = true;
        if (!/[0-9@#$%&*!^]/.test(control?.value))
          pswErrors[ErrorKey.hasNumberOrSpecial] = true;

        if (pswErrors) {
          return resolve(pswErrors);
        } else {
          return resolve(null);
        }
      });
    };
  }

  static confirmPasswordValidator(otherControlName: string): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        // Get the value of the control being validated (confirmPassword)
        const confirmPassword = control.value;

        // Get the value of the other control (specified by otherControlName)
        const otherControl = control.parent.get(otherControlName);
        const password = otherControl?.value;

        if (password === confirmPassword) {
          resolve(null); // No error, passwords match
        } else {
          resolve({ passwordMismatch: true }); // Passwords don't match
        }
      });
    };
  }

  static defaultCountryValidator(
    countriesListControlName: string,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const countriesList: number[] = control?.parent?.get(
          countriesListControlName,
        )?.value;

        const defaultCountry = control?.value;
        if (!defaultCountry) {
          resolve({ required: true });
        } else if (countriesList && countriesList.includes(defaultCountry)) {
          resolve(null); // No error
        } else {
          resolve({ defaultCountryIdNotInCountries: true });
        }
      });
    };
  }
  // custom validator for list of strings, if there is an array with an empty string it should be invalid
  static validateListOfStrings(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      return new Promise((resolve) => {
        const value = JSON.parse(control.value);
        // if valu is an empty array or has values of empty string/s return error
        if (value.length === 0 || value.some((item) => item === '')) {
          resolve({ required: true });
        }
        resolve(null);
      });
    };
  }
}
