import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, throwError, shareReplay, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPagingRequest, IFilterOptions } from '@shared/utility/global-enums';
import {
  IMeter,
  IMeterReplacement,
  MeasuringPointType,
  MeterName,
  MeterStatus,
  MeterNameList,
} from '@features/meters/store/meters.interface';

@Injectable({
  providedIn: 'root',
})
export class MetersService {
  apiBaseUrl = '';
  endpoint = 'organizations';

  private meterFiltersCache$: Observable<any>;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  // enhanced flattenFilter to handle all filter cases
  flattenFilter(
    filter: any,
    search: string = '',
    additionalData: any = {},
  ): any {
    return Object.keys(filter).reduce(
      (acc, key) => {
        if (Array.isArray(filter[key]) && filter[key].length > 0) {
          acc[key] = filter[key];
        } else if (
          !Array.isArray(filter[key]) &&
          filter[key] !== undefined &&
          filter[key] !== null
        ) {
          acc[key] = filter[key];
        }
        return acc;
      },
      { search, ...additionalData },
    );
  }

  // create a new function to get meters with pagination with post request
  getMetersWithPagination(
    pagingRequest: IPagingRequest,
    filter: any,
    search: string,
    isExport: boolean = false,
    options?: any,
  ): Observable<any> {
    const filters = this.flattenFilter(filter, search);
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/get?isExport=${isExport}`,
      {
        pagingRequest,
        filters,
      },
      {
        ...options,
      },
    );
  }
  getMeters(
    pagingRequest: any,
    filter: any,
    search: string,
    propertyData: any,
  ): Observable<any[]> {
    const filters = this.flattenFilter(filter, search, propertyData);
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/get/names`,
      {
        pagingRequest,
        filters,
      },
    );
  }

  getMeterCount(filter: any): Observable<number> {
    const filters = this.flattenFilter(filter);
    return this.http.post<number>(
      `${this.apiBaseUrl}${this.endpoint}/meters/get/count`,
      { filters },
    );
  }

  getMeterNameList(): Observable<MeterNameList> {
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/get/names`,
      {
        pagingRequest: {
          pageNumber: 1,
          // pageSize: 1000,
          orderBy: 'MeterName',
          isDescending: true,
        },
      },
    );
  }
  // cached filter refactor to globaly remove cache when changing organization
  // getMeterFilters(): Observable<any> {
  //   if (!this.meterFiltersCache$) {
  //     this.meterFiltersCache$ = this.http
  //       .get<any>(`${this.apiBaseUrl}${this.endpoint}/meters/filters`, {})
  //       .pipe(
  //         shareReplay(1),
  //         catchError((error) => {
  //           this.meterFiltersCache$ = null;
  //           return throwError(error);
  //         }),
  //       );
  //   }
  //   return this.meterFiltersCache$;
  // }
  getMeterFilters(): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/filters`,
      {},
    );
  }

  changeStatus(meterUID: string, status: MeterStatus): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/status`,
      { meterStatusID: status },
    );
  }

  deleteMeter(meterUID: string): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}`,
    );
  }

  createMeter(meterDetails: IMeter): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}${this.endpoint}/meters`, {
      meterGeneralInfo: meterDetails.meterGeneralInfo,
      currentReplacement: meterDetails.currentReplacement,
      transformerSetting: meterDetails.transformerSetting,
      groupUIDs: meterDetails.groupUIDs,
      virtualMeterChildGroups: meterDetails?.virtualMeterChildGroups || [],
      virtualMeterChildMeters: meterDetails?.virtualMeterChildMeters || [],
    });
  }

  updateMeter(meterDetails: IMeter): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterDetails.meterUID}`,
      {
        internalMeterID: meterDetails.internalMeterID,
        meterGeneralInfo: meterDetails.meterGeneralInfo,
        currentReplacement: meterDetails.currentReplacement,
        transformerSetting: meterDetails.transformerSetting,
        groupUIDs: meterDetails.groupUIDs,
      },
    );
  }

  getReplacementHistory(meterUID: string): Observable<IMeterReplacement[]> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/history`,
      {},
    );
  }

  createReplacement(
    meterUID: string,
    replacement: IMeterReplacement,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement`,
      replacement,
    );
  }

  updateReplacement(
    meterUID: string,
    replacement: IMeterReplacement,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/${replacement.meterReplacementUID}`,
      replacement,
    );
  }

  deleteReplacement(
    meterUID: string,
    meterReplacementUID: string,
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/${meterReplacementUID}`,
    );
  }
}
