import { createAction, props } from '@ngrx/store';

import {
  DASHBOARD_PAGE,
  DASHBOARD_URL,
  FilterParam,
  IDashboardPage,
  IEmbedParamsResponse,
} from './dashboard.interface';

export enum DashboardActionTypes {
  GetEmbedParams = '[Dashboards] Get Embed Params',
  GetEmbedParamsSuccess = '[Dashboards] Get Embed Params Success',
  GetEmbedParamsError = '[Dashboards] Get Embed Params Error',

  SetOpenDashboardFilter = '[Dashboard] Set Open Dashboard Filter',
  DashboardError = '[Dashboard] Error',
  ShowErrorMessage = '[Dashboard] Show Errors Messages',

  SetFilters = '[Dashboard] Set Filters',
  SetFiltersSuccess = '[Dashboard] Set Filters Success',
  SetFiltersError = '[Dashboard] Set Filters Error',

  setSelectedDashboard = '[Dashboard] Set Selected Dashboard',
  SetActivePage = '[Dashboard] Set Active Page',

  ResetDashboard = '[Dashboard] Reset Dashboard',

  GetDistinctMetersByMeterGroups = '[Dashboard] Get Distinct Meters By Meter Group',
  GetDistinctMetersByMeterGroupsSuccess = '[Dashboard] Get Distinct Meters By Meter Group Success',

  GetTotalGroupsMeterCount = '[Dashboard] Get Total Meter Count For All Groups',
  GetTotalGroupsMeterCountSuccess = '[Dashboard] Get Total Meter Count For All Groups Success',

  GetTotalMeterCount = '[Dashboard] Get Total Meter Count',
  GetTotalMeterCountSuccess = '[Dashboard] Get Total Meter Count Success',
}

export const getEmbedParams = createAction(DashboardActionTypes.GetEmbedParams);

export const getEmbedParamsSuccess = createAction(
  DashboardActionTypes.GetEmbedParamsSuccess,
  props<{ embedParams: IEmbedParamsResponse }>(),
);

export const getEmbedParamsError = createAction(
  DashboardActionTypes.GetEmbedParamsError,
  props<{ error: any }>(),
);

export const dashboardError = createAction(
  DashboardActionTypes.DashboardError,
  props<{ error: any }>(),
);

export const showErrors = createAction(
  DashboardActionTypes.ShowErrorMessage,
  props<{ message: string }>(),
);

export const setFilters = createAction(
  DashboardActionTypes.SetFilters,
  props<{ filters: any }>(),
);

export const setSelectedDashboard = createAction(
  DashboardActionTypes.setSelectedDashboard,
  props<{ dashboard: DASHBOARD_URL }>(),
);

export const setOpenDashboardFilter = createAction(
  DashboardActionTypes.SetOpenDashboardFilter,
);

export const setActivePage = createAction(
  DashboardActionTypes.SetActivePage,
  props<{ page: DASHBOARD_PAGE }>(),
);

export const resetDashboard = createAction(DashboardActionTypes.ResetDashboard);

export const getDistinctMetersByMeterGroups = createAction(
  DashboardActionTypes.GetDistinctMetersByMeterGroups,
  props<{ meterGroupUIDs: string[] }>(),
);

export const getDistinctMetersByMeterGroupsSuccess = createAction(
  DashboardActionTypes.GetDistinctMetersByMeterGroupsSuccess,
  props<{ distinctMeters: number }>(),
);

export const getTotalGroupsMeterCount = createAction(
  DashboardActionTypes.GetTotalGroupsMeterCount,
);

export const getTotalGroupsMeterCountSuccess = createAction(
  DashboardActionTypes.GetTotalGroupsMeterCountSuccess,
  props<{ totalGroupsMeterCount: number }>(),
);

export const getTotalMeterCount = createAction(
  DashboardActionTypes.GetTotalMeterCount,
  props<{ filters: any }>(),
);

export const getTotalMeterCountSuccess = createAction(
  DashboardActionTypes.GetTotalMeterCountSuccess,
  props<{ totalMeterCount: number }>(),
);
