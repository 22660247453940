import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IMeterGroupState } from './meter-group.state';
import { IMeterGroup, IMeterGroupView } from './meter-group.interface';
import { AccessObject } from '@features/user/store/user.interface';

// Define a feature selector to select the ISysMeterGroup state
const selectMeterGroupState =
  createFeatureSelector<IMeterGroupState>('meterGroups');

export const selectMeterGroupList = createSelector(
  selectMeterGroupState,
  (state) => state.filteredMetersGroup,
);

export const selectMeterGroupListForParent = createSelector(
  selectMeterGroupState,
  (state) => {
    const result: IMeterGroupView[] = [];

    // Helper function to recursively filter the tree structure
    function filterMeterGroups(
      meterGroups: IMeterGroupView[],
      excludeUID: string | null,
    ): void {
      meterGroups.forEach((group) => {
        // Skip any group that is a child of the excluded group
        if (excludeUID && group.meterGroupUID === excludeUID) {
          return;
        }

        // If the group doesn't have meters and it's not the selected group, add it to the result
        if (
          group.groupType === 'Parent' &&
          group.meterGroupUID !== state.selectedMeterGroup.meterGroupUID
        ) {
          result.push(group);
        }

        // Recursively apply the filtering logic to children
        if (group.children && group.children.length > 0) {
          filterMeterGroups(group.children, excludeUID);
        }
      });
    }

    // Start the recursive filtering, excluding all child groups of the selected group
    filterMeterGroups(
      state.meterGroups,
      state.selectedMeterGroup ? state.selectedMeterGroup.meterGroupUID : null,
    );

    return result;
  },
);

export const selectMeterGroupListWithoutChildren = createSelector(
  selectMeterGroupState,
  (state) => {
    const result: AccessObject[] = [];

    // Helper function to recursively filter the tree structure
    function filterMeterGroupsWithoutChildren(
      meterGroups: IMeterGroupView[],
    ): void {
      meterGroups.forEach((group) => {
        // If the group doesn't have children and it's not the selected group, add it to the result
        if (
          !group.children ||
          (group.children.length === 0 &&
            group.meterGroupUID !== state.selectedMeterGroup?.meterGroupUID)
        ) {
          result.push({ name: group.meterGroupName, uid: group.meterGroupUID });
        }

        // Recursively apply the filtering logic to children
        if (group.children && group.children.length > 0) {
          filterMeterGroupsWithoutChildren(group.children);
        }
      });
    }

    // Start the recursive filtering
    filterMeterGroupsWithoutChildren(state.meterGroups);

    return result;
  },
);

export const selectSelectedMeterGroup = createSelector(
  selectMeterGroupState,
  (state) => state.selectedMeterGroup,
);

export const selectNewMeterGroupProp = createSelector(
  selectMeterGroupState,
  (state) => state.newMeterGroup,
);

export const selectValidationErrorMeterGroup = createSelector(
  selectMeterGroupState,
  (state) => state.ValidationErrors,
);

export const selectMeterGroupsCreateEditDrawer = createSelector(
  selectMeterGroupState,
  (state) => state.drawersState.editCreateMeterGroupDrawer,
);
export const selectAddMetersDrawer = createSelector(
  selectMeterGroupState,
  (state) => state.drawersState.addMetersDrawer,
);
export const searchResultGroupsCount = createSelector(
  selectMeterGroupState,
  (state) => state.searchResultCount,
);

export const selectedFiltered = createSelector(
  selectMeterGroupState,
  (state) => (state.searchText.length > 0 ? true : false),
);
export const selectedLastChangedGroup = createSelector(
  selectMeterGroupState,
  (state) => state.lastGroupUpdated,
);

export const selectMeterGroupMeterCount = createSelector(
  selectMeterGroupState,
  (state) => state.selectedMeterGroup.metersCount,
);

export const selectMeterUpdateMessage = createSelector(
  selectMeterGroupState,
  (state) => state.selectedMeterGroup.meterUpdate,
);
export const selectedGroupHasChildren = createSelector(
  selectMeterGroupState,
  (state) => {
    // Helper function to find the group by UID
    function findGroupByUID(
      meterGroups: IMeterGroupView[],
      uid: string,
    ): IMeterGroupView | null {
      for (const group of meterGroups) {
        if (group.meterGroupUID === uid) {
          return group;
        }
        if (group.children && group.children.length > 0) {
          const found = findGroupByUID(group.children, uid);
          if (found) {
            return found;
          }
        }
      }
      return null;
    }

    // Get the UID from the selected meter
    const selectedMeterUID = state.selectedMeterGroup?.meterGroupUID;

    if (!selectedMeterUID) {
      return false; // If there's no selected meter group, return false
    }

    // Find the group by UID
    const selectedGroup = findGroupByUID(state.meterGroups, selectedMeterUID);

    // Return true if the group has children, false otherwise
    return selectedGroup
      ? selectedGroup.children && selectedGroup.children.length > 0
      : false;
  },
);

export const selectMeterUIDsToAdd = createSelector(
  selectMeterGroupState,
  (state) => state.selectedMeterGroup.meterUIDsToAdd,
);

export const selectMeterUIDsToRemove = createSelector(
  selectMeterGroupState,
  (state) => state.selectedMeterGroup.meterUIDsToRemove,
);

export const selectSaveButton = createSelector(
  selectMeterGroupState,
  (state) => state.saveButton,
);

export const selectCurrentStepOrTab = createSelector(
  selectMeterGroupState,
  (state) => state.currentStepOrTab,
);

export const selectVirtualMeterGroupsList = createSelector(
  selectMeterGroupState,
  (state) => state.filteredVirtualMeterGroupsList,
);
