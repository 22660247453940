import { UtilityService } from '@features/organizations/store/organizations.interface';
import {
  DASHBOARD_URL,
  FilterParam,
  IDashboardState,
  UNIT_ENERGY_VALUE,
  UNIT_DEMAND_VALUE,
  UNIT_CONSUMER_ENERGY_VALUE,
  UNIT_TEMPERATURE_VALUE,
  ConfigType,
  DASHBOARD_PAGE,
} from './dashboard.interface';
import * as FilterSchemas from '@features/dashboard/data/filter.schemas';
import { MeasuringPointType } from '@features/meters/store/meters.interface';
const now = new Date();
export const initialDashboardState: IDashboardState = {
  embedParams: null,
  error: null,
  isLoading: true,
  filters: {
    [FilterParam.Date_Range]: [
      new Date(now.getFullYear(), now.getMonth() - 1, 1),
      new Date(now.getFullYear(), now.getMonth(), now.getDate()),
    ],
    [FilterParam.Meter_Group]: [],
    [FilterParam.Meters_Single]: [],
    [FilterParam.Meters_Multiple]: [],
    [FilterParam.Tempeture_Unit]: UNIT_TEMPERATURE_VALUE.FAHRENHEIT,
    [FilterParam.Energy_Unit]: UNIT_ENERGY_VALUE.KWH,
    [FilterParam.Demand_Unit]: UNIT_DEMAND_VALUE.KWH,
    [FilterParam.Utility_Service]: 'Electricity',
    [FilterParam.Consumer_Energy_Unit]: UNIT_CONSUMER_ENERGY_VALUE.KWH,
  },
  shouldCloseDashboard: false,
  selectedDashboard: null,
  selectedDashboardSlicers: null,
  distinctMetersByMeterGroups: 0,
  totalGroupsMeterCount: 0,
  totalMeterCount: 0,

  dashboardParams: {
    [DASHBOARD_URL.ENERGY_OVERVIEW]: {
      title: $localize`Energy Overview`,
      value: DASHBOARD_URL.ENERGY_OVERVIEW,
      filters: [
        FilterParam.Date_Range,
        FilterParam.Meter_Group,
        FilterParam.Energy_Unit,
        FilterParam.Tempeture_Unit,
      ],
      meterFilters: {
        UtilityServiceId: ['Electricity'],
      },
    },
    [DASHBOARD_URL.DEMAND_OVERVIEW]: {
      title: $localize`Demand Overview by Meter Groups`,
      value: DASHBOARD_URL.DEMAND_OVERVIEW,
      filters: [
        FilterParam.Date_Range,
        FilterParam.Meter_Group,
        FilterParam.Demand_Unit,
        FilterParam.Tempeture_Unit,
      ],
    },
    [DASHBOARD_URL.WATER_OVERVIEW]: {
      title: $localize`Water Overview`,
      value: DASHBOARD_URL.WATER_OVERVIEW,
      filters: [FilterParam.Date_Range, FilterParam.Meter_Group],
    },
    [DASHBOARD_URL.ENERGY_AND_DEMANDS]: {
      title: $localize`Energy and Demands`,
      value: DASHBOARD_URL.ENERGY_AND_DEMANDS,
      filters: [
        FilterParam.Date_Range,
        FilterParam.Meters_Single,
        FilterParam.Consumer_Energy_Unit,
        FilterParam.Tempeture_Unit,
      ],
      meterFilters: {
        UtilityServiceId: [UtilityService.Electricity],
      },
    },
    [DASHBOARD_URL.VOLTAGES]: {
      title: $localize`Voltages`,
      value: DASHBOARD_URL.VOLTAGES,
      filters: [FilterParam.Date_Range, FilterParam.Meters_Single],
      meterFilters: {
        UtilityServiceId: [UtilityService.Electricity],
      },
    },
    [DASHBOARD_URL.EVENTS]: {
      title: $localize`Events`,
      value: DASHBOARD_URL.EVENTS,
      filters: [FilterParam.Date_Range, FilterParam.Meters_Single],
    },
    [DASHBOARD_URL.EVENT_OVERVIEW]: {
      title: $localize`Event Overview`,
      value: DASHBOARD_URL.EVENT_OVERVIEW,
      filters: [FilterParam.Date_Range, FilterParam.Meter_Group],
    },
    [DASHBOARD_URL.EVENT_LOG]: {
      title: $localize`Event Log`,
      value: DASHBOARD_URL.EVENT_LOG,
      filters: [FilterParam.Date_Range, FilterParam.Meter_Group],
    },
    [DASHBOARD_URL.VALIDATION_OVERVIEW]: {
      title: $localize`Validation Overview`,
      value: DASHBOARD_URL.VALIDATION_OVERVIEW,
      filters: [FilterParam.Date_Range, FilterParam.Meter_Group],
    },
    [DASHBOARD_URL.TRANSFORMER_LOAD]: {
      title: $localize`Transformer Load`,
      value: DASHBOARD_URL.TRANSFORMER_LOAD,
      filters: [FilterParam.Date_Range, FilterParam.Meters_Multiple],
      meterFilters: {
        MeasuringPointTypeId: [MeasuringPointType.Transformer],
      },
    },
  },
  dashboardSlicers: {
    [FilterParam.Date_Range]: {
      slicerTitle: 'SlicerSelectDate', // POWERBI
      filterParam: FilterParam.Date_Range,
      filterSlicer: FilterSchemas.DateFilter,
      params: { startDate: new Date(), endDate: new Date() },
      type: 'Filter',
      slicerName: '7ea2c14c5fb3a1e8bc5d',
      configType: ConfigType.Details,
    },
    [FilterParam.Meter_Group]: {
      slicerTitle: 'SlicerSelectGroups', // POWERBI
      filterParam: FilterParam.Meter_Group,
      filterSlicer: FilterSchemas.GroupLayerFilter,
      params: { group: [] },
      type: 'Slicer',
      slicerName: '2f9dea60a8e40c08237a',
      isFilterHeader: true,
      configType: ConfigType.Details,
    },
    [FilterParam.Meters_Single]: {
      slicerTitle: 'SlicerSelectMeters', // POWERBI
      filterParam: FilterParam.Meters_Single,
      filterSlicer: FilterSchemas.MeterFilter,
      params: { meters: [] },
      type: 'Slicer',
      slicerName: '', //replace with the slicer name
      isFilterHeader: true,
      configType: ConfigType.Details,
    },
    [FilterParam.Meters_Multiple]: {
      slicerTitle: 'SlicerSelectMeters', // POWERBI
      filterParam: FilterParam.Meters_Multiple,
      filterSlicer: FilterSchemas.MeterFilter,
      params: { meters: [] },
      type: 'Slicer',
      slicerName: '', //replace with the slicer name
      isFilterHeader: true,
      configType: ConfigType.Details,
    },
    [FilterParam.Energy_Unit]: {
      slicerTitle: 'SlicerSelectEnergyUnits', // POWERBI
      filterParam: FilterParam.Energy_Unit,
      filterSlicer: FilterSchemas.EnergyUnitFilter,
      params: { demandUnit: UNIT_ENERGY_VALUE.MWH },
      type: 'Slicer',
      slicerName: '9d0736bc0592886a0057',
      configType: ConfigType.Unit,
    },
    // DUPLICATE FOR DEMAND_OVERVIEW
    [FilterParam.Demand_Unit]: {
      slicerTitle: 'SlicerSelectDemandUnits', // POWERBI
      filterParam: FilterParam.Demand_Unit,
      filterSlicer: FilterSchemas.DemandUnitFilter,
      params: { demandUnit: UNIT_DEMAND_VALUE.MWH },
      type: 'Slicer',
      slicerName: '9d0736bc0592886a0057',
      configType: ConfigType.Unit,
    },
    [FilterParam.Tempeture_Unit]: {
      slicerTitle: 'SlicerSelectTempUnits', // POWERBI
      filterParam: FilterParam.Tempeture_Unit,
      filterSlicer: FilterSchemas.TempetureUnitFilter,
      params: { unit: UNIT_TEMPERATURE_VALUE.FAHRENHEIT },
      type: 'Slicer',
      slicerName: '6359cf300b0e9185200b',
      configType: ConfigType.Unit,
    },
    [FilterParam.Utility_Service]: {
      slicerTitle: 'SlicerSelectUtilityService', // POWERBI
      filterParam: FilterParam.Utility_Service,
      filterSlicer: FilterSchemas.UtilityServiceFilter,
      params: { utilityService: 'Electricity' },
      type: 'Slicer',
      slicerName: '6359cf300b0e9185200b',
      configType: ConfigType.Unit,
    },
    [FilterParam.Consumer_Energy_Unit]: {
      slicerTitle: 'SlicerSelectConsumerEnergyUnits',
      filterParam: FilterParam.Consumer_Energy_Unit,
      filterSlicer: FilterSchemas.ConsumerEnergyUnitFilter,
      params: { unit: UNIT_CONSUMER_ENERGY_VALUE.MWH },
      type: 'Slicer',
      slicerName: '',
      configType: ConfigType.Unit,
    },
  },
  activePage: null,
  pagesActivity: {
    [DASHBOARD_URL.ENERGY_OVERVIEW]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.ENERGY_OVERVIEW,
          name: 'ReportSection',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.ENERGY_OVERVIEW_DETAILS,
          name: 'ReportSectionc0fecb371d159a80d893',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
        {
          displayName: DASHBOARD_PAGE.ENERGY_OVERVIEW_INTERVAL,
          name: 'ReportSectionfc0b8db0900c34d5b6a1',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.DEMAND_OVERVIEW]: {
      isActive: false,
      waitingForData: true,
      pages: [
        {
          displayName: DASHBOARD_PAGE.DEMAND_OVERVIEW,
          name: 'ReportSectiona322459427211f739217',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.DEMAND_OVERVIEW_DETAILS,
          name: 'ReportSection238a6daf688a73a0a079',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.WATER_OVERVIEW]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.WATER_OVERVIEW,
          name: 'ReportSection',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.WATER_OVERVIEW_DETAILS,
          name: 'ReportSectionc0fecb371d159a80d893',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
        {
          displayName: DASHBOARD_PAGE.WATER_OVERVIEW_INTERVAL,
          name: 'ReportSectionfc0b8db0900c34d5b6a1',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.ENERGY_AND_DEMANDS]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.ENERGY_AND_DEMANDS,
          name: 'ed45a5c8503b91deb195',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.ENERGY_AND_DEMANDS_DETAILS,
          name: 'a09f2e0ae330662c5515',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
        {
          displayName: DASHBOARD_PAGE.ENERGY_AND_DEMANDS_REPLACEMENT,
          name: '56b0ea7399addda99c28',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
        {
          displayName: DASHBOARD_PAGE.ENERGY_AND_DEMANDS_INTERVAL,
          name: '54aac02c244909080284',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.VOLTAGES]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.VOLTAGES,
          name: 'ed45a5c8503b91deb195',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
      ],
    },
    [DASHBOARD_URL.EVENTS]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.EVENTS,
          name: 'ed45a5c8503b91deb195',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.EVENTS_DETAILS,
          name: 'a09f2e0ae330662c5515',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
        {
          displayName: DASHBOARD_PAGE.EVENTS_INTERVAL,
          name: '54aac02c244909080284',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.VALIDATION_OVERVIEW]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.VALIDATION_OVERVIEW,
          name: 'ReportSection50bc28002471ca404aa2',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.VALIDATION_OVERVIEW_DETAILS,
          name: 'ReportSection3be3a7b1104eb2951a0b',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
        {
          displayName: DASHBOARD_PAGE.VALIDATION_OVERVIEW_INTERVAL,
          name: 'ReportSectionbc6938c2cb6826dabfc7',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.EVENT_OVERVIEW]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.EVENT_OVERVIEW,
          name: '3651662e026439097090',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
        {
          displayName: DASHBOARD_PAGE.EVENT_OVERVIEW_DETAILS,
          name: '220b03724e5205550cae',
          isActive: false,
          isMain: false,
          disableFilter: true,
        },
      ],
    },
    [DASHBOARD_URL.EVENT_LOG]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.EVENT_LOG,
          name: '3651662e026439097090',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
      ],
    },
    [DASHBOARD_URL.TRANSFORMER_LOAD]: {
      isActive: false,
      pages: [
        {
          displayName: DASHBOARD_PAGE.TRANSFORMER_LOAD,
          name: '3651662e026439097090',
          isActive: false,
          isMain: true,
          disableFilter: false,
        },
      ],
    },
  },
};
