// This enum is used for validators and as a response form for the back-end.
export enum ErrorKey {
  required = 'required', // for all
  notNumber = 'notNumber',
  alphabeticCharactersOnly = 'alphabeticCharactersOnly',
  invalid = 'invalid', //mail
  notUniqueEmail = 'notUniqueEmail',
  pswLength = 'pswLength', //pasword
  pswNotStrong = 'pswNotStrong',
  hasLowercase = 'hasLowercase',
  hasUppercase = 'hasUppercase',
  hasNumberOrSpecial = 'hasNumberOrSpecial',
  pswMaxLengthExceed = 'pswMaxLengthExceed',
  invalidOtp = 'invalidOtp', // OTP
  expiredDate = 'expiredDate', // Future date
  resendRegistrationEmail = 'resendRegistrationEmail',
  passwordMismatch = 'passwordMismatch', //confirm
  invalidPhoneNumber = 'invalidPhoneNumber', //phone
  alreadyUseUserName = 'alreadyUseUserName', // Username
  //Validations for add/update department:
  departmentUIDDoesNotExist = 'departmentUIDDoesNotExist', //DepartmentUID=>
  unique = 'unique', //DepartmentName=>
  alreadyExistDepartmentName = 'alreadyExistDepartmentName', //DepartmentName=>
  storageNameNotExist = 'storageNameNotExist', //StorageName=>
  cannotUpdateStorage = 'cannotUpdateStorage', //StorageName=>
  OneOrMoreCountryNotExist = 'OneOrMoreCountryNotExist', //CountryIds=>
  cannotRemoveContryInUseInOrganization = 'cannotRemoveContryInUseInOrganization', //CountryIds=>
  cantUpdateCountries4DiffStorages = 'cantUpdateCountries4DiffStorages', //CountryIds=>
  defaultCountryIdNotInCountries = 'defaultCountryIdNotInCountries', //DefaultCountryId=>
  //delete departments
  departmentHasAssociatedObjects = 'departmentHasAssociatedObjects', //DepartmentUID=>
  cannotDeleteDepartment4DiffStorage = 'cannotDeleteDepartment4DiffStorage', //DepartmentUID=>
  cannotDeleteCurrentDepartment = 'cannotDeleteCurrentDepartment', //DepartmentUID=>
  notFutureDate = 'notFutureDate',
  invalidURL = 'invalidURL', // Parameter validation
  deleteDepartment = 'deleteDepartment',
  deleteChannelConfiguration = 'deleteChannelConfiguration',
  //Meter management drawer
  cannotBeParentOfItself = 'cannotBeParentOfItself',
  meterGroupCannotContainMetersIfHasOtherMeterGroups = 'meterGroupCannotContainMetersIfHasOtherMeterGroups',
  someMetersDoNotBelongToOrganization = 'someMetersDoNotBelongToOrganization',
  cannotUseParentMeterGroupThatHasMeters = 'cannotUseParentMeterGroupThatHasMeters',
  canOnlyBeConsumerIfNotElectricityMeasuringPoint = 'canOnlyBeConsumerIfNotElectricityMeasuringPoint', //for utility service that is not electricity measuring point value can only be consumer
  specifiedValueIsNotSupported = 'specifiedValueIsNotSupported',
  mandatoryForCountryWithStates = 'mandatoryForCountryWithStates',
  requiredIfCitySelected = 'requiredIfCitySelected',
  mustBeTransformer = 'mustBeTransformer',
  mandatoryForTransformer = 'mandatoryForTransformer',
  givenMeterGroupWasNotDetected = 'givenMeterGroupWasNotDetected', // "Given group was not detected. Please make sure that the group name is valid and a group that is not defined as the parent of another group and belongs to the list of groups available to you."
  notDefinedInDepartmentCountries = 'notDefinedInDepartmentCountries',
  requestTypeIsDuplicated = 'requestTypeIsDuplicated',
  invalidCRONExpressionForRequest = 'invalidCRONExpressionForRequest',
  missingOrganizationRequestsForSomeRequestType = 'missingOrganizationRequestsForSomeRequestType',
  cantDeleteCurrentOrganization = 'cantDeleteCurrentOrganization',
  metersMustBeInSrcOrganization = 'metersMustBeInSrcOrganization',
  meterNamesAlreadyExistInDestOrganization = 'meterNamesAlreadyExistInDestOrganization',
  sourceOrganizationCannotBeEqualToDestinationOrganization = 'sourceOrganizationCannotBeEqualToDestinationOrganization',
  cantUpdateCurrentOrganizationStatus = 'cantUpdateCurrentOrganizationStatus',
  cannotViewDeactivatedOrganizations = 'cannotViewDeactivatedOrganizations',
  sourceTypeMustBeUniqueWithinUtilityService = 'sourceTypeMustBeUniqueWithinUtilityService',
  cannotBeDeletedBecauseItIsAssignedToOneOrMoreMeters = 'cannotBeDeletedBecauseItIsAssignedToOneOrMoreMeters',
  cannotBeGreaterThanLastInstallationDate = 'cannotBeGreaterThanLastInstallationDate', //"InstallationDate cannot be greater than last installation date."
  cannotDeleteCurrentReplacement = 'cannotDeleteCurrentReplacement', //is current, you cant delete current replacement
  mustBeBetweenThePreviousAndNextInstallationDate = 'mustBeBetweenThePreviousAndNextInstallationDate', //"must be between the previous and next installation date"
  mustBeOneOfTheFollowing = 'mustBeOneOfTheFollowing', //"must be one of the following:"
  notExistInOrganizationCountry = 'notExistInOrganizationCountry',
  notExistInState = 'notExistInState',
  notExistInTheSystem = 'notExistInTheSystem',
  alreadyExist = 'alreadyExist',
  alreadyExistInOrganization = 'alreadyExistInOrganization',
  alreadyExistInPeriod = 'alreadyExistInPeriod',
  cannotDefineToItself = 'cannotDefineToItself',
  cannotBeFutureDate = 'cannotBeFutureDate',
  min = 'min',
  max = 'max',
  greaterThan$0 = 'greaterThan$0',
  greaterThan = 'greaterThan', // new
  equalGreaterThan = 'equalGreaterThan', // new
  positiveNumberUpTo = 'positiveNumberUpTo', // new
  noEmailAddressForUser = 'noEmailAddressForUser', // new
  notExist = 'notExist', // new
  maxCharacters = 'maxCharacters', // new
  unsupportedFileType = 'unsupportedFileType', // new
  unsupportedStatus = 'unsupportedStatus', // new
  doesNotHaveDataTemplate = 'doesNotHaveDataTemplate', // new
  onlySysAdminCanCreateSysAdmin = 'onlySysAdminCanCreateSysAdmin', // new
  mustIncludeCurrentDepartment = 'mustIncludeCurrentDepartment', // new
  cannotDefineViewerAndAdminRolesTogether = 'cannotDefineViewerAndAdminRolesTogether', // new
  cannotDefineInAdditionToMeterGroups = 'cannotDefineInAdditionToMeterGroups', // new
  cannotDefineDepartmentsForSelectedRoles = 'cannotDefineDepartmentsForSelectedRoles', // new
  cannotDefineMeterGroupsForSelectedRoles = 'cannotDefineMeterGroupsForSelectedRoles', // new
  defaultCountryNotInCountries = 'defaultCountryNotInCountries', // new
  cannotUpdateExpiredAccount = 'cannotUpdateExpiredAccount', // new
  notAssigned2Dept = 'notAssigned2Dept', // new
  stateNotDefinedInCountry = 'stateNotDefinedInCountry', // new
  invalidTransformerType = 'invalidTransformerType',
  cannotRemoveCountryInUseInOrganization = 'cannotRemoveCountryInUseInOrganization',
  validationFailure = 'validationFailure',
  validationErrorOccurred = 'validationErrorOccurred',
  decimalNumberNotBetweenZeroAndOne = 'decimalNumberNotBetweenZeroAndOne',
  deleteOrganization = 'deleteOrganization',
  organizationHasAssociatedObjects = 'organizationHasAssociatedObjects',
  meterGroupUIDs = 'meterGroupUIDs',
  cannotUpdateCountries4DiffStorages = 'cannotUpdateCountries4DiffStorages',
  CreationFailed = 'CreationFailed',
  CreationPending = 'CreationPending',
  CreationReading = 'CreationReading',
  CreationProcessing = 'CreationProcessing',
  CreationPartial = 'CreationPartial',
  CreationSuccessful = 'CreationSuccessful',
  UpdatePending = 'UpdatePending',
  UpdateReading = 'UpdateReading',
  UpdateProcessing = 'UpdateProcessing',
  UpdatePartial = 'UpdatePartial',
  UpdateSuccessful = 'UpdateSuccessful',
  UpdateFailed = 'UpdateFailed',
  CreationMeterPartialMsg = 'CreationMeterPartialMsg',
  CreationMeterSuccessfulMsg = 'CreationMeterSuccessfulMsg',
  CreationFailedMsg = 'CreationFailedMsg',
  UpdateMeterPartialMsg = 'UpdateMeterPartialMsg',
  UpdateMeterSuccessfulMsg = 'UpdateMeterSuccessfulMsg',
  UpdateFailedMsg = 'UpdateFailedMsg',
  updateOrganizationStatus = 'updateOrganizationStatus',
  NotUniqueChannel = 'notUniqueChannel',
  InstallationDateShouldBeDate = 'InstallationDateShouldBeDate',
  invalidDecimal = 'invalidDecimal',
  duplicateName = 'duplicateName',
  fileNotPending = 'fileNotPending',
  mustBeUniqueWithinOrganization = 'mustBeUniqueWithinOrganization',
  cannotBeDeletedUsersHaveAccessOnlyToOneGroup = 'cannotBeDeletedUsersHaveAccessOnlyToOneGroup',
  cannotBeDeletedBecauseInUseInReportConfigs = 'cannotBeDeletedBecauseInUseInReportConfigs',
  cannotBeDeletedBecauseItIsInUse = 'cannotBeDeletedBecauseItIsInUse',
  cannotConnectToSftp = 'cannotConnectToSftp',
  deleteDeliveryMethod = 'deleteDeliveryMethod',
  dateNotLaterThanGivenDate = 'dateNotLaterThanGivenDate',
  CannotRemoveMappingIfChannelsAreInUseByMeterReplacement = 'CannotRemoveMappingIfChannelsAreInUseByMeterReplacement',
}

// Show labels for UI kit components.
// NOTICE: Keys must be in the enum ErrorKey.
export const ErrorKeyLabels = {
  alphabeticCharactersOnly: $localize`Alphabetic characters only`,
  notNumber: $localize`Not a number`,
  invalid: $localize`Invalid field`,
  notUniqueEmail: $localize`Email is already in use`,
  invalidURL: $localize`invalid URL`,
  required: $localize`Required`,
  pswLength: $localize`Password should be at least 8 characters long`,
  pswNotStrong: $localize`The password must contain at least 3 of 4 character classes - uppercase, lowercase, number, symbol`,
  hasLowercase: $localize`Has lowercase letter`,
  hasUppercase: $localize`Has uppercase letter`,
  hasNumberOrSpecial: $localize`Has number or special character`,
  pswMaxLengthExceed: $localize`Your password length must not exceed 64 `,
  invalidOtp: $localize`Incorrect code`,
  passwordMismatch: $localize`Passwords do not match.`,
  invalidPhoneNumber: $localize`Invalid phone number`,
  expiredDate: $localize`Please enter a future expiration date `,
  alreadyUseUserName: $localize`Username is already in use`,
  resendRegistrationEmail: $localize`The account status isn't pending`,
  departmentUIDDoesNotExist: $localize`The specified Department UID does not exist.`,
  unique: $localize`The Department Name must be unique.`,
  alreadyExistDepartmentName: $localize`This Department Name already exists.`,
  storageNameNotExist: $localize`The specified Storage Name does not exist.`,
  cannotUpdateStorage: $localize`Unable to update the Storage information.`,
  OneOrMoreCountryNotExist: $localize`One or more of the specified Country IDs do not exist.`,
  cannotRemoveContryInUseInOrganization: $localize`Cannot remove a country that is in use within the organization.`,
  cantUpdateCountries4DiffStorages: $localize`Cannot update countries for different storages simultaneously.`,
  defaultCountryIdNotInCountries: $localize`The default country is not in the list of countries.`,
  departmentHasAssociatedObjects: $localize`The department has associated objects and cannot be deleted.`,
  cannotDeleteDepartment4DiffStorage: $localize`Cannot delete a department from a different storage`,
  cannotDeleteCurrentDepartment: $localize`The current department cannot be deleted.`,
  notFutureDate: $localize`Please enter a future date`,
  deleteDepartment: $localize`Delete department`,
  deleteChannelConfiguration: $localize`Delete channel configuration`,
  cannotBeParentOfItself: $localize`Cannot Be Its Own Parent`,
  meterGroupCannotContainMetersIfHasOtherMeterGroups: $localize`Group Cannot Have Meters If It Has Subgroups`,
  someMetersDoNotBelongToOrganization: $localize`Some Meters Not In Organization`,
  cannotUseParentMeterGroupThatHasMeters: $localize`Cannot Use Group With Meters As Parent`,
  canOnlyBeConsumerIfNotElectricityMeasuringPoint: $localize`Only Consumer For Non-Electricity`,
  specifiedValueIsNotSupported: $localize`Specified Value Not Supported`,
  mandatoryForCountryWithStates: $localize`Mandatory For Country With States`,
  requiredIfCitySelected: $localize`Required if city is selected`,
  mustBeTransformer: $localize`Must Be Transformer`,
  mandatoryForTransformer: $localize`Mandatory For Transformer`,
  givenMeterGroupWasNotDetected: $localize`Group Not Detected`,
  notDefinedInDepartmentCountries: $localize`Not Defined In Department Countries`,
  requestTypeIsDuplicated: $localize`Request Type Duplicated`,
  invalidCRONExpressionForRequest: $localize`Invalid CRON Expression`,
  missingOrganizationRequestsForSomeRequestType: $localize`Missing Organization Requests`,
  cantDeleteCurrentOrganization: $localize`Cannot delete current organization`,
  metersMustBeInSrcOrganization: $localize`Meters Must Be In Source Organization`,
  meterNamesAlreadyExistInDestOrganization: $localize`Meter Names Exist In Destination`,
  sourceOrganizationCannotBeEqualToDestinationOrganization: $localize`Source Cannot Be Destination`,
  cantUpdateCurrentOrganizationStatus: $localize`Cannot update current organization status`,
  cannotViewDeactivatedOrganizations: $localize`Cannot View Deactivated Organizations`,
  sourceTypeMustBeUniqueWithinUtilityService: $localize`Source Type Must Be Unique`,
  cannotBeDeletedBecauseItIsAssignedToOneOrMoreMeters: $localize`Cannot be deleted because it is assigned to one or more meters`,
  cannotBeGreaterThanLastInstallationDate: $localize`Must be after last Installation Date`,
  cannotDeleteCurrentReplacement: $localize`Cannot Delete Current Replacement`,
  mustBeBetweenThePreviousAndNextInstallationDate: $localize`Date Must Be Between The Previous and Next Installation Date`,
  mustBeOneOfTheFollowing: $localize`Must Be One Of The Following`,
  notExistInOrganizationCountry: $localize`Not In Organization Country`,
  notExistInState: $localize`Not in State`,
  notExistInTheSystem: $localize`Not In The System`,
  alreadyExist: $localize`Already exists`,
  alreadyExistInOrganization: $localize`Already Exists In Organization`,
  alreadyExistInPeriod: $localize`Already Exists In Period`,
  cannotDefineToItself: $localize`The meter cannot be defined as a power source for itself. But the easiest way is to remove the meter from the drop-down list`,
  cannotBeFutureDate: $localize`Can not be future date`,
  min: $localize`Minimum value required`,
  max: $localize`Maximum value required`,

  equalGreaterThan: $localize`Value must be equal to or greater than the specified limit.`,
  positiveNumberUpTo: $localize`Value must be a positive number up to the specified limit.`,
  noEmailAddressForUser: $localize`No email address found for the user.`,
  notExist: $localize`The specified entity does not exist.`,
  maxCharacters: $localize`Exceeds the maximum number of characters allowed.`,
  unsupportedFileType: $localize`The file type is not supported.`,
  unsupportedStatus: $localize`The status is not supported.`,
  doesNotHaveDataTemplate: $localize`No data template found.`,
  onlySysAdminCanCreateSysAdmin: $localize`Only a system administrator can create another system administrator.`,
  mustIncludeCurrentDepartment: $localize`Must include the current department.`,
  cannotDefineViewerAndAdminRolesTogether: $localize`Cannot assign both viewer and admin roles together.`,
  cannotDefineInAdditionToMeterGroups: $localize`Cannot define in addition to meter groups.`,
  cannotDefineDepartmentsForSelectedRoles: $localize`Cannot define departments for the selected roles.`,
  cannotDefineMeterGroupsForSelectedRoles: $localize`Cannot define groups for the selected roles.`,
  defaultCountryNotInCountries: $localize`The default country is not in the list of countries.`,
  cannotUpdateExpiredAccount: $localize`Cannot update an expired account.`,
  notAssigned2Dept: $localize`Not assigned to any department.`,
  stateNotDefinedInCountry: $localize`The state is not defined in the specified country.`,
  invalidTransformerType: $localize`The transformer type is invalid.`,
  cannotRemoveCountryInUseInOrganization: $localize`A country defined in an organization cannot be deleted.`,
  validationFailure: $localize`Validation Failure`,
  validationErrorOccurred: $localize`Validation Error Occurred`,
  decimalNumberNotBetweenZeroAndOne: $localize`Decimal number must be between 0 and 1`,
  deleteOrganization: $localize`Delete organization`,
  organizationHasAssociatedObjects: $localize`The organization has associated objects and cannot be deleted`,
  meterGroupUIDs: $localize`'meterGroupUIDs' must not be empty`,
  cannotUpdateCountries4DiffStorages: $localize`Cannot update countries for different storages`,
  greaterThan$0: (min: number) => $localize`Value must be greater than ${min}`,
  errorWritingFile: $localize`Error creating a file`,
  CreationFailed: $localize`Creation failed`,
  CreationPending: $localize`Creation pending`,
  CreationReading: $localize`Creation reading`,
  CreationProcessing: $localize`Creation processing`,
  CreationPartial: $localize`Creation partial`,
  CreationSuccessful: $localize`Creation successful`,
  UpdatePending: $localize`Update pending`,
  UpdateReading: $localize`Update reading`,
  UpdateProcessing: $localize`Update processing`,
  UpdatePartial: $localize`Update partial`,
  UpdateSuccessful: $localize`Update successful`,
  UpdateFailed: $localize`Update failed`,
  CreationMeterPartialMsg: (success: number, failure: number) =>
    $localize`Creation partial: ${success} successful, ${failure} failed. Please refresh the "Meters" page to display new data.`,
  CreationMeterSuccessfulMsg: (success: number) =>
    $localize`Creation successful: ${success}. Please refresh the "Meters" page to display new data.`,
  CreationFailedMsg: $localize`Creation failed`,
  UpdateMeterPartialMsg: (success: number, failure: number) =>
    $localize`Update partial: ${success} successful, ${failure} failed. Please refresh the "Meters" page to display new data`,
  UpdateMeterSuccessfulMsg: (success: number) =>
    $localize`Update successful: ${success}. Please refresh the "Meters" page to display new data`,
  UpdateFailedMsg: $localize`Update failed`,
  updateOrganizationStatus: $localize`Update organizaion status`,
  notUniqueChannel: $localize`Not unique`,
  InstallationDateShouldBeDate: $localize`Wrong format`,
  invalidDecimal: $localize`Wrong decimal format`,
  GroupUID: $localize`Group`,
  MeterGroupUID: $localize`Meter group`,
  RoleId: $localize`Role`,
  StatusId: $localize`Status`,
  CountryId: $localize`Country`,
  WeatherDataRequired: $localize`Weather data required`,
  ChannelConfigurationUID: $localize`Channel configuration`,
  IsOppositeConfiguration: $localize`Energy configuration`,
  MeasuringPointTypeId: $localize`Measuring point type`,
  MeterUID: $localize`Meter`,
  PowerSourceUID: $localize`Power source`,
  StatusID: $localize`Status`,
  StateId: $localize`State`,
  UtilityServiceId: $localize`Utility service`,
  FileImportTypeId: $localize`File import type`,
  UserUID: $localize`User`,
  duplicateName: $localize`The Name already exists`,
  City: $localize`City`,
  ReportTemplateID: $localize`Report template`,
  DeliveryMethodUID: $localize`Delivery method`,
  minLastConsumption: $localize`Min Last Consumption`,
  maxLastConsumption: $localize`Max Last Consumption`,
  ReportConfigStatus: $localize`Report configuration status`,
  fileNotPending: $localize`File is not in pending status`,
  mustBeUniqueWithinOrganization: $localize`Must be unique within organization`,
  cannotBeDeletedUsersHaveAccessOnlyToOneGroup: (param: string) =>
    $localize`Cannot be deleted because the users ${param} have access only to one group`,
  cannotBeDeletedBecauseInUseInReportConfigs: (param: string) =>
    $localize`Cannot be deleted because ${param} are in use in report configurations`,
  cannotBeDeletedBecauseItIsInUse: (param: string) =>
    $localize`This method cannot be deleted because it is used to generate custom reports: ${param}`,
  cannotConnectToSftp: (param: string) =>
    $localize`Cannot connect to SFTP server for the following reasons: ${param}`,
  deleteDeliveryMethod: $localize`Delete delivery method`,
  dateNotLaterThanGivenDate: $localize`Date must be later than the Min Last Consumption`,
  CannotRemoveMappingIfChannelsAreInUseByMeterReplacement: $localize`Cannot remove mapping if channels are in use by meter replacement`,
};
