import { IFilterDataForHeader } from '@shared/xpw-ui-kit/table/xpw-table-header.component';
import { models } from 'powerbi-client';

export interface IEmbedParamsResponse {
  embedReports: Array<{
    reportId: string;
    reportKey: string;
    embedUrl: string;
    reportName: string;
  }>;
  embedToken: {
    token: string;
    tokenId: string;
    expirationDate: Date;
  };
}

export interface IDashboardState {
  embedParams: IEmbedParamsResponse;
  isLoading: boolean;
  error: any;
  filters: any;
  // filtersView: any;
  shouldCloseDashboard: boolean;
  selectedDashboard: {
    url: DASHBOARD_URL;
    title: string;
    waitingForData?: boolean;
  } | null;
  dashboardParams: { [key in DASHBOARD_URL]: IDashboardParams };
  dashboardSlicers: { [key in FilterParam]: IDashboardSlicers };
  selectedDashboardSlicers: { [key in FilterParam]: IDashboardSlicers };
  activePage: IDashboardPage | null;
  pagesActivity: { [key in DASHBOARD_URL]: IDashboardPageActivity };

  distinctMetersByMeterGroups: number;
  totalGroupsMeterCount: number;
  totalMeterCount: number;
}

export interface IDashboardPageActivity {
  isActive: boolean;
  waitingForData?: boolean;
  pages: IDashboardPage[];
}
export interface IDashboardPage {
  displayName: DASHBOARD_PAGE;
  name: string;
  isActive: boolean;
  isMain: boolean;
  disableFilter: boolean;
}
export interface IDashboardParams {
  title: string;
  value: DASHBOARD_URL;
  filters: FilterParam[];
  meterFilters?: any;
}
export interface IDashboardSlicers {
  slicerTitle: string;
  filterParam: FilterParam;
  filterSlicer: (
    ...args: any
  ) => models.ReportLevelFilters | models.IHierarchyFilter;
  params: any;
  type: 'Filter' | 'Slicer';
  slicerName: string;
  isFilterHeader?: boolean;
  filterHeaderData?: IFilterDataForHeader;
  configType: ConfigType;
}
export enum ConfigType {
  Unit = 'Unit',
  Details = 'Details',
}
interface IDashboardFilters {
  name: string;
  filters: string[];
  filtersValues: any;
}
export enum DASHBOARD_URL {
  ENERGY_OVERVIEW = 'dashboard/energy-overview',
  DEMAND_OVERVIEW = 'dashboard/demand-overview',
  WATER_OVERVIEW = 'dashboard/water-overview',

  ENERGY_AND_DEMANDS = 'dashboard/energy-&-demands',
  VOLTAGES = 'dashboard/voltages',
  EVENTS = 'dashboard/events',

  EVENT_OVERVIEW = 'dashboard/event-overview',
  EVENT_LOG = 'dashboard/event-log',
  VALIDATION_OVERVIEW = 'dashboard/validation-overview',
  TRANSFORMER_LOAD = 'dashboard/transformer-load',
}
export enum DASHBOARD_PAGE {
  ENERGY_OVERVIEW = 'Energy Overview',
  ENERGY_OVERVIEW_DETAILS = 'Energy Overview - Details',
  ENERGY_OVERVIEW_INTERVAL = 'Energy Overview - Interval',
  DEMAND_OVERVIEW = 'Demand',
  DEMAND_OVERVIEW_DETAILS = 'Demand - Details',
  // DEMAND_OVERVIEW_INTERVAL = 'Demand Overview - Interval',
  WATER_OVERVIEW = 'Water Overview',
  WATER_OVERVIEW_DETAILS = 'Water Overview - Details',
  WATER_OVERVIEW_INTERVAL = 'Water Overview - Interval',
  ENERGY_AND_DEMANDS = 'Customer Service',
  ENERGY_AND_DEMANDS_DETAILS = 'Customer Service - Details',
  ENERGY_AND_DEMANDS_INTERVAL = 'Customer Service - Interval',
  ENERGY_AND_DEMANDS_REPLACEMENT = 'Customer Service - Replacement',
  VOLTAGES = 'Voltage',
  EVENTS = 'Event Data',
  EVENTS_DETAILS = 'Event Data - Details',
  EVENTS_INTERVAL = 'Event Data - Interval',
  VALIDATION_OVERVIEW = 'Validation',
  VALIDATION_OVERVIEW_DETAILS = 'Validation - Details',
  VALIDATION_OVERVIEW_INTERVAL = 'Validation - Interval',
  EVENT_OVERVIEW = 'Events Overview',
  EVENT_OVERVIEW_DETAILS = 'Event Overview - Details',
  EVENT_LOG = 'Summary Event Log',
  TRANSFORMER_LOAD = 'Transformer Load',
}
export enum UNIT_ENERGY_VALUE {
  MWH = "'Measures container'[Sum Energy AllUsedTypes MWh EO]", //"'Measures from energy_overview'[Sum Energy AllUsedTypes MWh]",
  KWH = "'Measures container'[Sum Energy AllUsedTypes kWh EO]", //"'Measures from energy_overview'[Sum Energy AllUsedTypes kWh]",
}
export enum UNIT_DEMAND_VALUE {
  KWH = "'Measures container'[DemandBy15Min Kilo DMND]",
  MWH = "'Measures container'[DemandBy15Min Mega DMND]",
}
export enum UNIT_TEMPERATURE_VALUE {
  CELSIUS = 'Celsius',
  FAHRENHEIT = 'Fahrenheit',
}
export enum UNIT_CONSUMER_ENERGY_VALUE {
  KWH = "'Measures container'[unit_1 CS]",
  MWH = "'Measures container'[unit_2 CS]",
  GWH = "'Measures container'[unit_3 CS]",
}

export enum FilterParam {
  Date_Range = 'dateRange',
  Meter_Group = 'MeterGroup',
  Meters_Single = 'MetersSingle',
  Meters_Multiple = 'MetersMultiple',
  Energy_Unit = 'DemandUnit',
  Demand_Unit = 'DemandUnitDO',
  Consumer_Energy_Unit = 'EnergyUnits',
  Tempeture_Unit = 'TempetureUnit',
  Utility_Service = 'UtilityService',
}

export const FilterParamOptions = {
  [FilterParam.Tempeture_Unit]: [
    { label: $localize`Fahrenheit`, value: UNIT_TEMPERATURE_VALUE.FAHRENHEIT },
    { label: $localize`Celsius`, value: UNIT_TEMPERATURE_VALUE.CELSIUS },
  ],
  [FilterParam.Demand_Unit]: [
    { label: $localize`MW-MVAr-MVA`, value: UNIT_DEMAND_VALUE.MWH },
    { label: $localize`kW-kVAr-kVA`, value: UNIT_DEMAND_VALUE.KWH },
  ],
  [FilterParam.Energy_Unit]: [
    { label: $localize`MWh-MVArh-MVAh`, value: UNIT_ENERGY_VALUE.MWH },
    { label: $localize`kWh-kVArh-kVAh`, value: UNIT_ENERGY_VALUE.KWH },
  ],
  [FilterParam.Utility_Service]: [
    { label: $localize`Electricity`, value: 'Electricity' },
  ],
  [FilterParam.Consumer_Energy_Unit]: [
    {
      label: $localize`MWh-MVArh-MVAh / m³`,
      value: UNIT_CONSUMER_ENERGY_VALUE.MWH,
    },
    {
      label: $localize`kWh-kVArh-kVAh / ft³`,
      value: UNIT_CONSUMER_ENERGY_VALUE.KWH,
    },
    {
      label: $localize`GWh-GVArh-GVAh / Gallons / MMBTU`,
      value: UNIT_CONSUMER_ENERGY_VALUE.GWH,
    },
  ],
};

export const FilterParamLabels = {
  [FilterParam.Meter_Group]: $localize`Groups`,
  [FilterParam.Meters_Single]: $localize`Meter`,
  [FilterParam.Meters_Multiple]: $localize`Meters`,
  [FilterParam.Energy_Unit]: $localize`Energy Unit`,
  [FilterParam.Demand_Unit]: $localize`Demand Unit`,
  [FilterParam.Consumer_Energy_Unit]: $localize`Consumer Energy Unit`,
  [FilterParam.Tempeture_Unit]: $localize`Tempeture Unit`,
  [FilterParam.Utility_Service]: $localize`Utility Service`,
  [FilterParam.Date_Range]: $localize`Period`,
};
