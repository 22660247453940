import {
  Component,
  Input,
  forwardRef,
  OnInit,
  OnChanges,
  SimpleChanges,
  input,
  signal,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';
import { AbstractNgModelComponent } from '../abstract-ng-model.component';

@Component({
  selector: 'xpw-form-schedular',
  template: `
    <div>
      <section>
        <nz-radio-group
          class="radio two"
          [ngModel]="activeTab()"
          (ngModelChange)="onTabChange($event)"
        >
          <label
            nz-radio-button
            nzValue="frequency"
            [nzDisabled]="frequencyDisabled()"
            >Scheduled Days
          </label>
          <label
            nz-radio-button
            nzValue="manualDates"
            [nzDisabled]="manualDisabled()"
            >Custom Days</label
          >
        </nz-radio-group>
        <nz-form-control
          [nzHasFeedback]="true"
          [nzValidateStatus]="errorStatus()"
          [nzErrorTip]="getErrorLabel()"
        >
          <div *ngIf="activeTab() === 'frequency'">
            <xpw-form-cron-jobs
              [cronTypes]="cronTypes()"
              [minutForHourly]="minutForHourly"
              [(ngModel)]="value"
              (ngModelChange)="onCronValueChange($event)"
            ></xpw-form-cron-jobs>
          </div>

          <div *ngIf="activeTab() === 'manualDates'">
            <xpw-form-manual-dates
              [(ngModel)]="value"
              [markAsDirtyOnChange]="markFormDirtyOnLoad"
              (ngModelChange)="onValueChange($event)"
            ></xpw-form-manual-dates>
          </div>
        </nz-form-control>
      </section>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormSchedularComponent),
      multi: true,
    },
  ],
})
export class XpwFormSchedularComponent
  extends AbstractNgModelComponent<any>
  implements OnChanges
{
  @Input() timeZoneForDisplayBottom: string = '';
  @Input() minutForHourly: number = 0;
  @Input() markFormDirtyOnLoad: boolean = false;
  @Output() tabChanged = new EventEmitter<'frequency' | 'manualDates'>();
  @Output() valueChanged = new EventEmitter<any>();
  initialTab = input<'frequency' | 'manualDates'>('frequency');
  cronTypes = input<string[]>(['Monthly', 'Weekly', 'Daily', 'Hourly']);

  activeTab = signal<'frequency' | 'manualDates'>('frequency');

  manualDisabled = input<boolean>(false);
  frequencyDisabled = input<boolean>(false);
  ngOnInit(): void {
    this.activeTab.set(this.initialTab());
    console.log('ngOnInit', this.value);
    if (this.value) {
      this.propagateValueToChild();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialTab'] && !changes['initialTab'].firstChange) {
      // this.activeTab = this.initialTab;
    }
    if (changes['value'] && !changes['value'].firstChange) {
      this.propagateValueToChild();
    }
  }

  private propagateValueToChild(): void {
    console.log('propagateValueToChild', this.value);
    if (this.activeTab() === 'frequency' && this.value) {
      console.log(' propagateValueToChild this.value', this.value);
      // Pass the value to the cron component
      // This assumes the value is in the correct format for the cron component
    }
  }

  onTabChange(tab: 'frequency' | 'manualDates'): void {
    this.activeTab.set(tab);
    this.tabChanged.emit(tab);
    // Reset value when changing tabs to prevent old data from being used
    this.value = null;

    // We don't need to call onChange here as it will mark the form as dirty
    // The child components will handle their own value changes

    this.propagateValueToChild();
  }

  onValueChange(value: any): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChanged.emit(value);
  }

  onCronValueChange(value: any): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.valueChanged.emit(value);
  }
}
