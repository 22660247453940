
    import { culture } from './culture/culture';
    import { RumInitConfiguration } from '@datadog/browser-rum';

    export enum DD_SESSION_REPLAY_RECORDING {
      FORCE = 'force',
      FALSE = 'false'
    }
      
    export const environment = {
      production: false,
      redirectUri: `https://web.dev.expertpower.com/${culture.env}`,
      apiBaseUrl: "https://webapi2.dev.expertpower.com/api/",
      apiBaseWS: "https://webapi2.dev.expertpower.com/",
      clientId: "987f2aaa-6377-46c8-b706-2ba3142b7185",
      b2cPolicies: {
        names: {
          signUpSignIn: "B2C_1A_SIGNIN_DEV",
        },
        authorities: {
          signUpSignIn: {
            authority: "https://expertpowerb2c.b2clogin.com/expertpowerb2c.onmicrosoft.com/B2C_1A_SIGNIN_DEV",
          },
        },
        authorityDomain: "expertpowerb2c.b2clogin.com",
      },
      ApiScopes: ['offline_access',
    'openid',
    'https://expertpowerb2c.onmicrosoft.com/xpwApi/api.scope'],
      appInsights: {
        instrumentationKey: "f3bbbcdc-2a6e-4b49-a4fd-69b0126c19b8",
      },
      datadog: {
          applicationId: "bd8845e7-d64a-47e3-ae2e-87215aa4655d",
          clientToken: "pubc3181950e959e8166796d912aa54ef13",
          site: "us3.datadoghq.com" as RumInitConfiguration['site'],
          service: "xpw-web",
          env: "dev",
          sessionSampleRate: 100,
          sessionReplaySampleRate: undefined,
          defaultPrivacyLevel: "mask-user-input" as RumInitConfiguration['defaultPrivacyLevel'],
          trackUserInteractions: true,
          SessionReplayRecording: DD_SESSION_REPLAY_RECORDING.FORCE 
        },
    };