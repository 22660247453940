import { FormGroup } from '@angular/forms';
import { IFilterOptions } from './global-enums';
import { uniq, isEqual, sortBy } from 'lodash';
import { TreeNode } from '@shared/xpw-ui-kit/form/select-tree/xpw-tree-form.component';
export class GlobalFunctions {
  static getOptionsByName(
    dataArray: IFilterOptions[],
    name: string,
    titlesFromEnumTitles?: any,
  ): any[] {
    if (Array.isArray(dataArray)) {
      // Find the object with the matching name
      const result = dataArray.find((item) => item.name === name);

      // If the object is found, convert its options and return them
      if (result && result?.options) {
        return result.options.map((option) => ({
          value: option.key,
          label: titlesFromEnumTitles
            ? titlesFromEnumTitles[option.key]
            : option.value,
        }));
      }
    }

    // If no object is found, return an empty array
    return [];
  }
  static convertToEnum(obj: any[], id: string): any {
    const enumObj: any = {};
    function traverse(node: any, parentName?: string) {
      const enumName = parentName ? `${parentName}_${node.name}` : node.name;
      enumObj[enumName] = node[id];

      if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => {
          traverse(child, enumName);
        });
      }
    }

    obj.forEach((item) => {
      traverse(item);
    });

    return enumObj;
  }
  static filterDuplicates(list: string[]): string[] {
    let uniqueList: string[] = [];
    // user lodash to remove duplicates and empty values
    uniqueList = uniq(list).filter((item) => item !== '');

    return uniqueList;
  }

  static filter<T>(items: T[], data: string, props: string[]) {
    return items.filter((item: T) => {
      let match = false;
      for (const prop of props) {
        if (prop.indexOf('.') > -1) {
          const value = this.resolve(prop, item);
          if (value && value.toUpperCase().indexOf(data) > -1) {
            match = true;
            break;
          }
          continue;
        }

        if (item[prop].toString().toUpperCase().indexOf(data) > -1) {
          match = true;
          break;
        }
      }
      return match;
    });
  }

  static resolve(path: string, obj: any) {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, obj || self);
  }

  //create function that gets object source, value property name and label property name and return array of objects with label and value
  static convertToOptions(
    source: any[],
    valueProp: string,
    labelProp: string,
    addNullResult = false,
  ): any[] {
    const nullResult = addNullResult ? [{ value: null, label: 'None' }] : [];
    return [
      ...nullResult,
      ...source.map((item) => ({
        value: item[valueProp],
        label: item[labelProp],
        checked: false,
        disabled: false,
      })),
    ];
  }
  //create function that gets object source, value property name and label property name and return array of objects with label and value
  static convertToMutltipleLabelOptions(
    source: any[],
    valueProp: string,
    labelProp: string[],
    addNullResult = false,
  ): any[] {
    const nullResult = addNullResult ? [{ value: null, label: 'None' }] : [];
    return [
      ...nullResult,
      ...source.map((item) => ({
        value: item[valueProp],
        // label: item[labelProp],
        labels: labelProp.map((label) => {
          const labelParts = label.split('.');
          let value = item;
          for (let i = 0; i < labelParts.length; i++) {
            value = value[labelParts[i]];
          }
          return value;
        }),
        checked: false,
        disabled: false,
      })),
    ];
  }

  static convertToOptionsWithTooltipData(
    source: any[],
    valueProp: string,
    labelProp: string,
    tooltipDataProp: string,
    addNullResult = false,
  ): any[] {
    const nullResult = addNullResult ? [{ value: null, label: 'None' }] : [];
    return [
      ...nullResult,
      ...source.map((item) => ({
        value: item[valueProp],
        label: item[labelProp],
        tooltipData: item[tooltipDataProp],
      })),
    ];
  }

  static convertEnumToOptions(
    enumObj: any,
    enumTitleobj: any,
    removeFromList?: any, //list of id's
    addNullResult = false,
  ): any[] {
    const nullResult = addNullResult ? [{ value: null, label: 'None' }] : [];
    return [
      ...nullResult,
      ...Object.keys(enumObj)
        .filter(
          (key) =>
            typeof enumObj[key] !== 'number' &&
            (removeFromList ? !removeFromList.includes(Number(key)) : true),
        ) // Filter out non-numeric keys
        .map((key) => {
          return {
            value: Number(key),
            label: enumTitleobj[key],
          };
        }),
    ];
  }

  //  a method that gets an object array, property key name, and property value nam, return it as type [key: string]: string;
  static convertToKeyValue(source: any[], keyProp: string, valueProp: string) {
    return source.reduce((acc, item) => {
      acc[item[keyProp]] = item[valueProp];
      return acc;
    }, {});
  }
  // Convert dictionary to options
  static convertDic2Options(data: { [key: string]: string }) {
    return Object.entries(data).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  }

  static downloadFileFromReponse(response: any) {
    const fileName = this.getFileNameFromHeaderContent(response.headers);
    const body = response.body;

    const url = window.URL.createObjectURL(body);
    //  get Content-Disposition: from response header

    var anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();
  }

  static getFileNameFromHeaderContent(headers: any): string {
    let filename = 'default.xlsx';

    try {
      let contentDisposition = headers.get('content-disposition');
      if (contentDisposition) {
        let filenamePart = contentDisposition
          .split(';')
          .map((part) => part.trim())
          .find((part) => part.startsWith('filename='));

        if (filenamePart) {
          filename = filenamePart.split('=')[1];
          filename = filename.replace(/"/g, '');
        }
      }
    } catch (e) {
      console.log(e);
    }
    return filename; // Outputs: InsertMetersDataTemplate.xlsx or default.xlsx
  }

  static checkErrorsFromForm(listOfKeys: string[], form: FormGroup): boolean {
    return listOfKeys.some((key) => form.get(key)?.errors != null);
  }

  static areObjectsEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every((key) => isEqual(sortBy(obj1[key]), sortBy(obj2[key])));
  }

  static convertToTreeNode(data: any[]): TreeNode[] {
    return data.map((item) => ({
      key: item.meterGroupUID,
      name: item.meterGroupName,
      children: this.convertToTreeNode(item.children),
      metersCount: item.metersCount ?? 0,
    }));
  }
}
