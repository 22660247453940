import { AccessObject } from '@features/user/store/user.interface';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { ICurrentUser } from './current-user.interface';
import {
  IMeterGroup,
  IMeterGroupView,
} from '@features/meter-group/store/meter-group.interface';
import { IDepartment } from '@features/departments/store/departments.interface';

export enum UserActions {
  GetCurrentUserData = '[User login] get Current User Data',
  GetCurrentUserDataSuccess = '[User login] get Current User Data Success',
  LoginFailure = '[User logout] loginFailure',
  // SetCurrentUserData = '[User setInfo] Set info for Current User',
  GetAccessObjects = '[User login] get Access Objects',
  SetAccessObjects = '[User set] Set Access Objects',
  Logout = '[User logout] logout',
  getCurrentOrganizations = '[User profile] get Current Organizations',
  getCurrentOrganizationsSuccess = '[User profile] get Current Organizations Success',
  SetCurrentOrganization = '[User profile] Set Current Organization',
  setCurrentDepartment = '[User profile] Set Current Department',
  RemoveLoading = '[User profile] Remove Loading',
  UpdateAccessObjects = '[User profile] Update Access Object from effects',
}

export const getCurrentUserData = createAction(UserActions.GetCurrentUserData);

export const getCurrentUserDataSuccess = createAction(
  UserActions.GetCurrentUserDataSuccess,
  props<{
    currentUser: ICurrentUser;
  }>(),
);
export const loginFailure = createAction(
  UserActions.LoginFailure,
  props<{ error: any }>(),
);
// export const setCurrentUserData = createAction(
//   UserActions.SetCurrentUserData,
//   props<{
//     currentUser: ICurrentUser;
//   }>(),
// );

export const getAccessObjects = createAction(UserActions.GetAccessObjects);

export const setAccessObjects = createAction(
  UserActions.SetAccessObjects,
  props<{
    accessObjects: {
      departments: AccessObject[];
      meterGroups: AccessObject[];
    };
  }>(),
);

export const updateAccessObjects = createAction(
  UserActions.UpdateAccessObjects,
  props<{
    departments: IDepartment[] | null;
    meterGroups: IMeterGroupView[] | null;
  }>(),
);

export const logout = createAction(UserActions.Logout);

export const getCurrentOrganizations = createAction(
  UserActions.getCurrentOrganizations,
  props<{ departmentUID: string }>(),
);

export const getCurrentOrganizationsSuccess = createAction(
  UserActions.getCurrentOrganizationsSuccess,
  props<{ organizations: any }>(),
);

export const setCurrentOrganization = createAction(
  UserActions.SetCurrentOrganization,
  props<{ organizationUID: string; departmentUID: string }>(),
);

export const removeLoading = createAction(UserActions.RemoveLoading);
