import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ICurrentUser } from 'src/app/store/current-user/current-user.interface';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { AccessObject } from '@features/user/store/user.interface';
import { UtilityService } from '@features/organizations/store/organizations.interface';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  apiBaseUrl = '';
  endpoint = 'users'; // Name form API

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }
  private _isAuthorized: ReplaySubject<boolean> = new ReplaySubject(1);

  get isAuthorized() {
    return this._isAuthorized.asObservable();
  }

  getLoggedInUser(): Observable<ICurrentUser> {
    return this.http.get<ICurrentUser>(
      this.apiBaseUrl + this.endpoint + '/current',
    );
  }

  getAccessObjects(): Observable<any> {
    return this.http.get<{
      departments: AccessObject[];
      metergroups: AccessObject[];
    }>(this.apiBaseUrl + this.endpoint + '/current/accessObjects');
  }

  setCurrentOrganization(
    organizationUID: string,
    departmentUID: string,
  ): Observable<any> {
    return this.http.put<any>(
      this.apiBaseUrl + this.endpoint + '/current/preferences',
      {
        organizationUID: organizationUID,
        departmentUID: departmentUID,
      },
    );
  }
}
