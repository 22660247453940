import { Injectable, Signal } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

//TO DO decide what 3rd party monitoring and logging

import { EmitEvent, EventBusService, Events } from './event-bus.service';
import { selectAuditMessage } from 'src/app/store/audit/audit.selector';
import { clearAuditAction } from 'src/app/store/audit/audit.actions';
import { CurrentUserFlowService } from '../current-user/current-user.service';

@Injectable()
export class EventInterceptor implements HttpInterceptor {
  audit: Signal<string>;

  constructor(
    private eventBus: EventBusService,
    private store: Store,
    private currentUserService: CurrentUserFlowService,
  ) {
    this.audit = store.selectSignal(selectAuditMessage);
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.currentUserService.isExpiredToken()) {
      this.currentUserService.logout();
    }
    this.eventBus.emit(new EmitEvent(Events.httpRequest, request));
    const headers: HttpHeaders = new HttpHeaders({
      AuditComments: this.audit(),
    });

    // Add headers to the request
    request = request.clone({
      headers: headers,
    });

    return next.handle(request).pipe(
      filter((event: HttpEvent<any>) => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        this.store.dispatch(clearAuditAction());
        if (event.body && event.body.toastMsg) {
          // TODO: handle toast message
        }
        if (event.body && event.body.data !== undefined) {
          // Replace the response body with the content of `data`
          event = event.clone({ body: event.body.data });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error && error.error.data) {
          const clonedError = new HttpErrorResponse({
            error: error.error.data,
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url,
          });
          this.eventBus.emit(new EmitEvent(Events.httpResponse, clonedError));
          if (error.error.toastMsg) {
            this.eventBus.emit(
              new EmitEvent(Events.toastMsg, error.error.toastMsg),
            );
          }
          throw clonedError;
        } else {
          this.eventBus.emit(new EmitEvent(Events.httpResponse, error));
          throw error;
        }
      }),
    );
  }
}
