import {
  Component,
  forwardRef,
  signal,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';
import { AbstractNgModelComponent } from '../abstract-ng-model.component';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { DateManagerService } from '@core/services/date-manager/date-manager.service';
import { differenceInCalendarDays } from 'date-fns';

const daysFromNow = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};
const daysFromDate = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};
@Component({
  selector: 'xpw-form-manual-dates',
  template: `
    <div>
      <!-- Placeholder for future implementation -->
      <section>
        <div class="flex flex-row gap-2">
          <xpw-input-date
            [value]="startDate().value"
            [readonly]="startDate().readonly"
            (dateChange)="startDateChanged($event)"
            [status]="startDate().status"
            label="Start date"
          />
          <xpw-input-time
            [value]="time"
            (onChange)="timeChanged($event)"
            [status]="startDate().status"
            label="Start time"
          />
        </div>

        @for (date of listOfDates(); track $index) {
          <div>
            <xpw-input-date
              [label]="'Report ' + ($index + 1)"
              (dateChange)="listDateChanged($event, $index)"
              [minDate]="date.minDate"
              [maxDate]="date.maxDate"
              [value]="date.value"
              [readonly]="date.readonly"
              [status]="date.status"
            >
              <xpw-button type="link" (click)="removeDate($index)">
                <xpw-icon icon="delete" />
              </xpw-button>
            </xpw-input-date>
          </div>
        }
        <xpw-button type="dashed" (click)="addDate()">
          <xpw-icon icon="add-plus" /> Add Next Report</xpw-button
        >
      </section>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwFormManualDatesComponent),
      multi: true,
    },
  ],
})
export class XpwFormManualDatesComponent
  extends AbstractNgModelComponent<any>
  implements OnInit
{
  // Add flag to prevent initial value from marking form as dirty
  private isInitializing = true;
  private daysDiff = 1;
  private dateService = inject(DateManagerService);
  // Add flag to allow external control over when to mark form as dirty
  @Input() markAsDirtyOnChange = true;

  listOfDates = signal<
    {
      value: Date;
      minDate: Date;
      maxDate?: Date;
      readonly?: boolean;
      status?: NzStatus;
    }[]
  >([
    {
      value: this.dateService.getOnlyDate(daysFromNow(2)),
      minDate: this.dateService.getOnlyDate(daysFromNow(1)),
      readonly: false,
      status: '',
    },
  ]);

  startDate = signal<{
    value: Date;
    minDate: Date;
    readonly?: boolean;
    status?: NzStatus;
    error?: string;
  }>({
    value: this.dateService.getOnlyDate(daysFromNow(1)),
    minDate: this.dateService.getOnlyDate(daysFromNow(1)),
    readonly: false,
    status: '',
    error: 'required',
  });

  time: Date | null = new Date(new Date().setHours(0, 0, 0, 0));

  hasDatePassed = (date: Date): boolean => {
    if (!date || isNaN(date.getTime())) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    return compareDate <= today;
  };

  // Utility method to ensure dates are always Date objects
  ensureDate(date: Date | string | null | undefined): Date | null {
    if (!date) return null;

    const parsedDate = date instanceof Date ? date : new Date(date);
    const dateOnly = this.dateService.getOnlyDate(parsedDate);
    // Check if date is valid, if not return null
    return isNaN(dateOnly.getTime()) ? null : dateOnly;
  }

  // New function to apply minDate and readonly to listOfDates
  applyStatusAndReadOnlyAndUpdate() {
    this.listOfDates.update((list) =>
      list.map((item, index) => {
        const minDate =
          index === 0
            ? this.calculateMinDateFirstReport()
            : list[index - 1].value;

        // Add maxDate logic - use next date as upper bound unless it's the last item
        const maxDate =
          index === list.length - 1
            ? null
            : daysFromDate(list[index + 1].value, -1);

        const readonly = this.hasDatePassed(item.value);
        // Only compare dates if both dates are valid
        const isError =
          readonly ||
          (item.value && minDate && item.value <= minDate) ||
          !item.value;
        const status = isError ? 'error' : '';
        return { ...item, minDate, maxDate, readonly, status };
      }),
    );
    this.updateValue();
  }

  calculateMinDateFirstReport() {
    // Make sure we have a valid start date
    const startDate = this.startDate().value;
    if (!startDate || isNaN(startDate.getTime())) {
      return this.dateService.getOnlyDate(daysFromNow(0)); // Use today as fallback
    }

    const minDate = this.hasDatePassed(startDate)
      ? this.dateService.getOnlyDate(daysFromNow(0))
      : startDate;

    return minDate;
  }
  // Updated listDateChanged to ensure date is a Date object
  listDateChanged(date: Date | string, index: number) {
    const dateObj = this.ensureDate(date);
    this.listOfDates.update((list) =>
      list.map((item, i) => {
        if (i === index) {
          return { ...item, value: dateObj };
        }
        return item;
      }),
    );
    this.applyStatusAndReadOnlyAndUpdate();
  }

  addDate() {
    const prevIndex = this.listOfDates().length - 1;
    const minDate: Date =
      prevIndex < 0
        ? this.startDate().value
        : this.listOfDates()[prevIndex].value;

    this.listOfDates.update((prev) => [
      ...prev,
      {
        value: this.dateService.getOnlyDate(
          new Date(minDate.getTime() + this.daysDiff * 24 * 60 * 60 * 1000),
        ),
        minDate,
        readonly: this.hasDatePassed(minDate),
      },
    ]);

    this.applyStatusAndReadOnlyAndUpdate();
  }

  startDateChanged(date: any) {
    this.startDate.set({
      ...this.startDate(),
      value: this.ensureDate(date),
      status: date == null ? 'error' : '',
      error: date == null ? 'required' : '',
    });
    this.applyStatusAndReadOnlyAndUpdate(); // Make sure to update min dates when start date changes
  }

  timeChanged(date: any) {
    // Validate that we have a proper date
    try {
      this.time = date;

      // If the date is invalid, set as null
      if (this.time && isNaN(this.time.getTime())) {
        this.time = null;
      }
    } catch (e) {
      // Any error would mean we have an invalid date
      this.time = null;
    }

    // Update status based on time validity
    // this.startDate.set({
    //   ...this.startDate(),
    //   status: this.time == null ? 'error' : '',
    // });

    this.applyStatusAndReadOnlyAndUpdate();
  }

  removeDate(index: number) {
    this.listOfDates.update((prev) => prev.filter((_, i) => i !== index));
    this.applyStatusAndReadOnlyAndUpdate();
  }

  ngOnInit(): void {
    // After a brief delay, initialization is complete
    setTimeout(() => {
      this.isInitializing = false;
    }, 0);
  }

  // Updated writeValue to ensure all dates are Date objects
  override writeValue(value: any): void {
    this.isInitializing = true; // Set flag to prevent onChange from marking form as dirty

    if (!value) {
      this.isInitializing = false;
      return;
    }

    if (value.startDate) {
      const startDateValue = this.ensureDate(value.startDate);
      // Only update if we have a valid date
      if (startDateValue) {
        this.startDate.set({
          value: startDateValue,
          minDate: startDateValue,
          readonly: this.hasDatePassed(startDateValue),
          status: '', // Reset status on valid date
        });
      } else {
        // Handle invalid date by using current date as fallback
        const fallbackDate = this.dateService.getOnlyDate(daysFromNow(1));
        this.startDate.set({
          value: fallbackDate,
          minDate: fallbackDate,
          readonly: false,
          status: '', // Reset status
        });
      }
    }

    if (value.hour !== undefined && value.minute !== undefined) {
      try {
        const date = new Date();
        date.setHours(value.hour || 0, value.minute || 0);
        this.time = isNaN(date.getTime()) ? new Date() : date;
      } catch (e) {
        // If there's an error, use current time as fallback
        this.time = new Date();
      }
    }

    if (value.dates?.length) {
      try {
        const validDates = value.dates
          .map((date: Date | string) => this.ensureDate(date))
          .filter((date) => date !== null);

        // If we have no valid dates, use a default one
        if (validDates.length === 0) {
          this.listOfDates.set([
            {
              value: this.dateService.getOnlyDate(daysFromNow(2)),
              minDate: this.dateService.getOnlyDate(daysFromNow(1)),
              readonly: false,
              status: '',
            },
          ]);
        } else {
          this.listOfDates.set(
            validDates.map((date: Date) => ({
              value: date,
              minDate: new Date(), // Temporary value, will be updated
              readonly: false, // Temporary value, will be updated
              status: '',
            })),
          );
        }

        this.applyStatusAndReadOnlyAndUpdate(); // Apply minDate and readonly after setting initial values
      } catch (e) {
        // If there's an error, use default values
        this.listOfDates.set([
          {
            value: this.dateService.getOnlyDate(daysFromNow(2)),
            minDate: this.dateService.getOnlyDate(daysFromNow(1)),
            readonly: false,
            status: '',
          },
        ]);
      }
    }

    // Reset flag after short delay to ensure writeValue completes
    setTimeout(() => {
      this.isInitializing = false;
    }, 0);
  }

  updateValue() {
    const value = {
      startDate: this.startDate().value,
      dates: this.listOfDates().map((date) => date.value),
      hour: this.time?.getHours() ?? null,
      minute: this.time?.getMinutes() ?? null,
    };

    // Calculate days difference
    const dates = value.dates;
    if (dates.length > 1) {
      this.daysDiff = Math.abs(
        differenceInCalendarDays(
          dates[dates.length - 1],
          dates[dates.length - 2],
        ),
      );
    } else if (dates.length === 1) {
      this.daysDiff = Math.abs(
        differenceInCalendarDays(dates[0], value.startDate),
      );
    } else {
      this.daysDiff = 1; // Default value if no dates are set
    }

    // Only call onChange and onTouched if not initializing
    if (!this.isInitializing) {
      // Only mark as dirty when markAsDirtyOnChange is true
      if (this.markAsDirtyOnChange) {
        this.onChange(value);
        this.onTouched();
      } else {
        // Still update the value in the form model but don't mark as dirty
        if (this.onChange) {
          this.onChange(value);
        }
      }
    }
  }
}
