import { Component, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICurrentUser } from 'src/app/store/current-user/current-user.interface';
import {
  selectCurrentOrganization,
  selectCurrentUserProperties,
} from 'src/app/store/current-user/current-user.selector';

@Component({
  selector: 'xpw-organization-badge',
  template: ` <div class="organization-badge">
    <nz-avatar
      style="color:rgba(0, 0, 0, 0.45); background-color:rgba(0, 0, 0, 0.06);"
      nzIcon="xpw-outline:organizations"
      [nzSrc]="sourceIconImage()"
    >
      wefwefw</nz-avatar
    >
    <H3>{{ organizationName() }} </H3>
  </div>`,
  styleUrls: ['./organization-badge.component.less'],
})
export class OrganizationBadgeComponent {
  constructor(private store: Store) {}

  sourceIconImage = computed(() => this.currentOrganization().icon);
  currentOrganization = this.store.selectSignal(selectCurrentOrganization);

  organizationName = computed(() => {
    const org: any = this.currentOrganization();
    if (!org) return '';
    return org.name;
  });
}
