// src/app/user/state/dashboard.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as DashboardActions from './dashboard.actions';

import { DashboardService } from '@core/api/dashboard.service';
import { NotificationService } from '@core/services/notifications/notifications.service';
import { Store } from '@ngrx/store';
import { MeterGroupsService } from '@core/api/meter-group.service';
import { FilterParam } from './dashboard.interface';
import {
  selectDashBoardParams,
  selectSelectedDashboard,
  selectSelectedDashboardSlicers,
} from './dashboard.selector';
import { MetersService } from '@core/api/meters.service';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private apiService: DashboardService,
    private notification: NotificationService,
    private meterGroupService: MeterGroupsService,
    private meterService: MetersService,
    private store: Store,
  ) {}

  getEmbedParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getEmbedParams),
      mergeMap(() =>
        this.apiService.getEmbedParams().pipe(
          map((embedParamsResponse) => {
            return embedParamsResponse;
          }),
          map((embedParams) =>
            DashboardActions.getEmbedParamsSuccess({ embedParams }),
          ),
          catchError((error) =>
            of(DashboardActions.getEmbedParamsError({ error })),
          ),
        ),
      ),
    ),
  );

  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.showErrors),
        tap((action) => {
          this.notification.error(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );
  setFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setFilters),
        withLatestFrom(this.store.select(selectSelectedDashboardSlicers)),
        // filter((action) => action.filters[FilterParam.Meter_Group].length > 0),

        tap(([action, selectedDashboard]) => {
          if (
            Object.keys(selectedDashboard).includes(FilterParam.Meter_Group)
          ) {
            const meterGroupUIDs = action.filters[FilterParam.Meter_Group].map(
              (meterGroup) => getLastChildKey(meterGroup),
            );

            this.store.dispatch(
              DashboardActions.getDistinctMetersByMeterGroups({
                meterGroupUIDs,
              }),
            );
          }
        }),
        catchError((error) => of(DashboardActions.dashboardError({ error }))),
      ),
    { dispatch: false },
  );
  getDistinctMetersByMeterGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getDistinctMetersByMeterGroups),
      mergeMap((action) =>
        this.meterGroupService
          .getDistinctMetersByMeterGroups(action.meterGroupUIDs)
          .pipe(
            map((distinctMeters) =>
              DashboardActions.getDistinctMetersByMeterGroupsSuccess({
                distinctMeters,
              }),
            ),
            catchError((error) =>
              of(DashboardActions.dashboardError({ error })),
            ),
          ),
      ),
    ),
  );

  getTotalGroupsMeterCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getTotalGroupsMeterCount),
      mergeMap((action) =>
        this.meterGroupService.getDistinctMetersByMeterGroups([]).pipe(
          map((distinctMeters) =>
            DashboardActions.getTotalGroupsMeterCountSuccess({
              totalGroupsMeterCount: distinctMeters,
            }),
          ),
        ),
      ),
    ),
  );

  getTotalMeterCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.getTotalMeterCount),
      mergeMap((action) =>
        this.meterService
          .getMeterCount(action.filters)
          .pipe(
            map((totalMeterCount) =>
              DashboardActions.getTotalMeterCountSuccess({ totalMeterCount }),
            ),
          ),
      ),
    ),
  );

  setSelectedDashboard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.setSelectedDashboard),
        filter((action) => action.dashboard.includes('dashboard')),
        withLatestFrom(this.store.select(selectDashBoardParams)),
        tap(([action, dashboardParams]) => {
          // const filters = dashboardParams[action.dashboard].meterFilters;
          this.store.dispatch(
            DashboardActions.getTotalMeterCount({
              filters: dashboardParams[action.dashboard].meterFilters ?? {},
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}

function getLastChildKey(meterGroup: any): string {
  if (!meterGroup.children) return meterGroup.key;
  return getLastChildKey(meterGroup.children);
}
