// src/app/Meter/state/Meter.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as MeterActions from './meters.actions';
import { MetersService } from '@core/api/meters.service';
import { NotificationService } from '@core/services/notifications/notifications.service';
import { Store } from '@ngrx/store';
import {
  selectMeterFilter,
  selectMeterListPagingRequest,
  selectMeterSearch,
  selectSelectedMeter,
} from './meters.selector';
import { initialMeterState } from './meters.state';
import { MeterStatusTitle } from '@shared/utility/global-enums-titles';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { clearAuditAction } from 'src/app/store/audit/audit.actions';
import { IMeter } from './meters.interface';

@Injectable()
export class MeterEffects {
  constructor(
    private actions$: Actions,
    private apiService: MetersService,
    private notification: NotificationService,
    private store: Store,
  ) {}

  getMeterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.getMeterList),
      withLatestFrom(
        this.store.select(selectMeterFilter),
        this.store.select(selectMeterSearch),
        this.store.select(selectSelectedMeter),
      ),
      switchMap(([action, meterFilter, meterSearch, selectedMeter]) =>
        this.apiService
          .getMetersWithPagination(
            action.pagingRequest,
            meterFilter,
            meterSearch,
            false,
            null,
          )
          .pipe(
            concatMap((meters) => [
              MeterActions.getMeterListSuccess({ meters }),
              MeterActions.selectMeter({ meterUID: selectedMeter.meterUID }),
            ]),
            catchError((error) =>
              of(MeterActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );
  getMeterNamesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.getMeterNameList),
      mergeMap(() =>
        this.apiService.getMeterNameList().pipe(
          map((meterNames) =>
            MeterActions.getMeterNameListSuccess({ meterNames }),
          ),
          catchError((error) =>
            of(MeterActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );
  searchChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.searchChange),
      withLatestFrom(this.store.select(selectMeterListPagingRequest)),
      distinctUntilChanged(),
      map(([action, pagingRequest]) =>
        MeterActions.getMeterList({
          pagingRequest: {
            ...initialMeterState.pagingRequest,
            pageSize: pagingRequest.pageSize,
            orderBy: pagingRequest.orderBy,
            isDescending: pagingRequest.isDescending,
          },
        }),
      ),
    ),
  );
  getFilterOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.getFilterOptions),
      mergeMap((action) =>
        this.apiService.getMeterFilters().pipe(
          map((filterOptions) =>
            MeterActions.getFilterOptionsSuccess({
              filterOptions: filterOptions?.filters,
            }),
          ),
          catchError((error) =>
            of(MeterActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  filterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.filterChange),
      // distinctUntilChanged((prev, current) =>
      //   GlobalFunctions.areObjectsEqual(prev.filter, current.filter),
      // ),
      withLatestFrom(this.store.select(selectMeterListPagingRequest)),
      map(([action, pagingRequest]) =>
        MeterActions.getMeterList({
          pagingRequest: {
            ...initialMeterState.pagingRequest,
            pageSize: pagingRequest.pageSize,
            orderBy: pagingRequest.orderBy,
            isDescending: pagingRequest.isDescending,
          }, // exclude page size from initial state
        }),
      ),
    ),
  );

  suspendMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.changeStatus),
      withLatestFrom(this.store.select(selectMeterListPagingRequest)),
      mergeMap(([action, pagingRequest]) =>
        this.apiService
          .changeStatus(action.updatedMeter.meterUID, action.status)
          .pipe(
            mergeMap(() => {
              const updatedMeter: IMeter = {
                ...action.updatedMeter,
                meterGeneralInfo: {
                  ...action.updatedMeter.meterGeneralInfo,
                  meterStatusID: action.status,
                },
              };
              return of(
                MeterActions.showMetersSuccessMessage({
                  message:
                    'Meter status was changed to ' +
                    MeterStatusTitle[action.status],
                }),
                // MeterActions.getMeterList({ pagingRequest: pagingRequest }),
                MeterActions.updateMeterSuccess({ updatedMeter }),
              );
            }),
            catchError((error) =>
              of(MeterActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  createMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.createMeter),
      // withLatestFrom(this.store.select(selectMeterListPagingRequest)),
      tap(() => this.notification.info(undefined, 'creating Meter')),
      mergeMap((action) =>
        this.apiService.createMeter(action.meter).pipe(
          mergeMap((newMeterResponse) => {
            const createdMeter = {
              ...newMeterResponse,
            };
            return of(
              MeterActions.createMeterSuccess({ createdMeter }),
              MeterActions.closeMeterDrawer(),
            );
          }),
          catchError((ValidationErrors) =>
            of(MeterActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );
  createMeterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.createMeterSuccess),
        tap(() => this.notification.success(undefined, 'Meter created')),
      ),
    { dispatch: false },
  );

  updateMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.updateMeter),
      // withLatestFrom(this.store.select(selectMeterListPagingRequest)),
      tap(() => this.notification.info(undefined, 'updating Meter')),
      exhaustMap((action) =>
        this.apiService.updateMeter(action.meter).pipe(
          mergeMap((response) => {
            const updatedMeter = {
              ...action.meter,
              groupUIDs: action.meter.groupUIDs ?? [],
            };
            return of(
              MeterActions.updateMeterSuccess({ updatedMeter }),
              MeterActions.closeMeterDrawer(),
            );
          }),
          catchError((ValidationErrors) =>
            of(MeterActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );

  updateMeterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.updateMeterSuccess),
        tap(() => this.notification.success(undefined, 'Meter updated')),
      ),
    { dispatch: false },
  );

  deleteMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.deleteMeter),
      withLatestFrom(this.store.select(selectMeterListPagingRequest)),
      mergeMap(([action, pagingRequest]) =>
        this.apiService.deleteMeter(action.meterUID).pipe(
          mergeMap(() =>
            of(
              MeterActions.deleteMeterSuccess({ meterUID: action.meterUID }),
              MeterActions.closeMeterDrawer(),
            ),
          ),
          catchError((ValidationErrors) =>
            of(MeterActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );
  deleteMeterSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.deleteMeterSuccess),
        tap(() => this.notification.success(undefined, 'Meter deleted')),
      ),
    { dispatch: false },
  );
  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.showErrors),
        tap((action) => {
          // this.notification.error(action.message);
        }),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.showMetersSuccessMessage),
        tap((action) => {
          this.notification.success(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );

  getReplacementHystory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.getReplacementHystory),
      withLatestFrom(this.store.select(selectSelectedMeter)),
      mergeMap(([action, selectedMeter]) =>
        this.apiService.getReplacementHistory(selectedMeter.meterUID).pipe(
          map((replacementList) =>
            MeterActions.getReplacementHystorySuccess({
              replacementList: replacementList,
            }),
          ),
          catchError((error) =>
            of(MeterActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );
  createReplacement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.createReplacement),
      withLatestFrom(
        this.store.select(selectSelectedMeter),
        this.store.select(selectMeterListPagingRequest),
      ),
      mergeMap(([action, selectedMeter, pagingRequest]) =>
        this.apiService
          .createReplacement(selectedMeter.meterUID, action.replacement)
          .pipe(
            mergeMap(() =>
              of(
                MeterActions.getReplacementHystory(),
                MeterActions.getMeterList({ pagingRequest }),
                MeterActions.closeReplacementDrawer(),
              ),
            ),
            catchError((ValidationErrors) =>
              of(MeterActions.validationError({ ValidationErrors })),
            ),
          ),
      ),
    ),
  );
  updateReplacement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.updateReplacement),
      withLatestFrom(
        this.store.select(selectSelectedMeter),
        this.store.select(selectMeterListPagingRequest),
      ),
      mergeMap(([action, selectedMeter, pagingRequest]) =>
        this.apiService
          .updateReplacement(selectedMeter.meterUID, action.replacement)
          .pipe(
            mergeMap(() =>
              of(
                MeterActions.showMetersSuccessMessage({
                  message: 'Replacement updated',
                }),
                MeterActions.getReplacementHystory(),
                MeterActions.getMeterList({ pagingRequest }),
                MeterActions.closeReplacementDrawer(),
              ),
            ),
            catchError((ValidationErrors) =>
              of(MeterActions.validationError({ ValidationErrors })),
            ),
          ),
      ),
    ),
  );
  deletedReplacement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.deleteReplacement),
      withLatestFrom(
        this.store.select(selectSelectedMeter),
        this.store.select(selectMeterListPagingRequest),
      ),

      mergeMap(([action, selectedMeter, pagingRequest]) =>
        this.apiService
          .deleteReplacement(selectedMeter.meterUID, action.meterReplacementUID)
          .pipe(
            mergeMap(() =>
              of(
                MeterActions.showMetersSuccessMessage({
                  message: 'Replacement deleted',
                }),
                MeterActions.getReplacementHystory(),
                MeterActions.getMeterList({ pagingRequest }),
                clearAuditAction(),
                MeterActions.closeReplacementDrawer(),
              ),
            ),
            catchError((error) =>
              of(MeterActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  exportMeterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.exportMeterList),
      withLatestFrom(
        this.store.select(selectMeterListPagingRequest),
        this.store.select(selectMeterFilter),
        this.store.select(selectMeterSearch),
        this.store.select(selectSelectedMeter),
      ),
      tap(() => this.notification.loading(undefined, 'Exporting Meters....')),
      mergeMap(
        ([action, pagingRequest, meterFilter, meterSearch, selectedMeter]) =>
          this.apiService
            .getMetersWithPagination(
              pagingRequest,
              meterFilter,
              meterSearch,
              true,
              {
                observe: 'response',
                responseType: 'blob' as 'json',
              },
            )
            .pipe(
              tap((response) => response.headers.get('Content-Disposition')),
              map((response) =>
                MeterActions.exportMeterListSuccess({ response: response }),
              ),
              catchError((error) =>
                of(MeterActions.showErrors({ message: error })),
              ),
            ),
      ),
    ),
  );

  exportMeterListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.exportMeterListSuccess),
        map((action) => {
          GlobalFunctions.downloadFileFromReponse(action.response);
          this.notification.clearAllNotifications();
          this.notification.success(undefined, 'Meters exported');
        }),
      ),
    { dispatch: false },
  );
}
