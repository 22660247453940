import { models } from 'powerbi-client';

const GLOBAL_FILTER_SCHEMA = 'http://powerbi.com/product/schema#basic';
const GLOBAL_FILTER_SCHEMA_HIERARCHY =
  'http://powerbi.com/product/schema#hierarchy'; //need to change to hierarchy
const DATE_TABLE = 'bi_date_table';
const DATE_COLUMN = 'date';
const GROUP_TABLE = 'bi_meters_groups';
const GROUP_COLUMN = 'l0_group_uid';
const GROUP_COLUMN_LEVEL = (level: number) => `l${level}_group_uid`;

const UNIT_DEMAND_TABLE = 'Parameter Units EO';
const UNIT_DEMAND_COLUMN = 'Parameter Units EO Fields';

const UNIT_ENERGY_VALUE_DO_TABLE = 'Parameter Units DMND';
const UNIT_ENERGY_VALUE_DO_COLUMN = 'Parameter Units DMND Fields';

const TEMP_UNIT_TABLE = 'Parameter Units Temperature';
const TEMP_COLUMN = 'Parameter SwitchTempCF Fields';

const METER_TABLE = 'bi_meters_groups'; //table: 'bi_meters_groups', column: 'l0_group_name'
const METER_COLUMN = 'meter_uid';

const UTILITY_SERVICE_TABLE = 'bi_meters_groups';
const UTILITY_SERVICE_COLUMN = 'utility_service_enum';

const Consumer_Energy_Unit_TABLE = 'Parameter Units Consumption CS';
const Consumer_Energy_Unit_COLUMN = 'Parameter Units Consumption CS Fields';

export function DateFilter(
  startDate: Date,
  endDate: Date,
): models.ReportLevelFilters {
  return {
    $schema: GLOBAL_FILTER_SCHEMA,
    target: {
      table: DATE_TABLE,
      column: DATE_COLUMN,
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: startDate,
      },
      {
        operator: 'LessThanOrEqual',
        value: endDate,
      },
    ],
  };
}

export function ConsumerEnergyUnitFilter(
  unit: string,
): models.ReportLevelFilters {
  return getBasicFilter(
    Consumer_Energy_Unit_TABLE,
    Consumer_Energy_Unit_COLUMN,
    [unit],
  );
}

export function TempetureUnitFilter(unit: string): models.ReportLevelFilters {
  return getBasicFilter(TEMP_UNIT_TABLE, TEMP_COLUMN, [unit]);
}

export function EnergyUnitFilter(unit: string): models.ReportLevelFilters {
  return getBasicFilter(UNIT_DEMAND_TABLE, UNIT_DEMAND_COLUMN, [unit]);
}
// DUPLICATE FOR DEMAND_OVERVIEW
export function DemandUnitFilter(unit: string): models.ReportLevelFilters {
  return getBasicFilter(
    UNIT_ENERGY_VALUE_DO_TABLE,
    UNIT_ENERGY_VALUE_DO_COLUMN,
    [unit],
  );
}

export function UtilityServiceFilter(
  utilityService: string,
): models.ReportLevelFilters {
  return getBasicFilter(UTILITY_SERVICE_TABLE, UTILITY_SERVICE_COLUMN, [
    utilityService,
  ]);
}

export function GroupFilter(group: string): models.ReportLevelFilters {
  return getBasicFilter(GROUP_TABLE, GROUP_COLUMN, [...group]);
}
export function MeterFilter(meter: any): models.ReportLevelFilters {
  const meterValues = meter.map((m: any) => m.value);
  return getBasicFilter(METER_TABLE, METER_COLUMN, meterValues);
}
function getBasicFilter(table: string, column: string, values: string[]): any {
  return {
    $schema: GLOBAL_FILTER_SCHEMA,
    filterType: models.FilterType.Basic,
    operator: 'In',
    requireSingleSelection: false,
    target: {
      table: table,
      column: column,
    },
    values: values,
  };
}

export function GroupLayerFilter(params: any[]): models.IHierarchyFilter {
  //  if param is an array with {name: string, level: number}
  //  find the highest level
  const highestLevel = getHighestLevel(params);

  return {
    $schema: GLOBAL_FILTER_SCHEMA_HIERARCHY,
    filterType: models.FilterType.Hierarchy,
    target: groupTarget(highestLevel),
    hierarchyData: groupValues(params, highestLevel),
  };
}

const groupTarget = (level: number) => {
  const target = [];
  for (let i = 0; i < level; i++) {
    target.push({
      table: GROUP_TABLE,
      hierarchy: 'group_name_hierarchy',
      hierarchyLevel: GROUP_COLUMN_LEVEL(i),
    });
  }
  return target;
};

const groupValues = (params: any[], highestLevel: number) => {
  const values: models.IHierarchyFilterNode[] = [];
  for (let i = 0; i < params.length; i++) {
    const levelValues = getLevelValues(params[i], highestLevel);
    values.push(...levelValues);
  }
  return values;
};

const getHighestLevel = (params: any[]) => {
  let highestLevel = 0;
  for (let i = 0; i < params.length; i++) {
    const level = getLevel(params[i]);
    if (level > highestLevel) {
      highestLevel = level;
    }
  }
  return highestLevel;
};

const getLevel = (param: any) => {
  if (!param.children) {
    return 1;
  }
  return 1 + getLevel(param.children);
};

const getLevelValues = (param: any, level: number) => {
  const values: models.IHierarchyFilterNode[] = [];
  let node: models.IHierarchyFilterNode = { value: param.key };
  if (param.children) {
    node = { ...node, operator: 'Inherited', children: [] };
    node.children.push(...getLevelValues(param.children, level - 1));
  } else {
    node = { ...node, operator: 'Selected' };
  }
  values.push(node);
  return values;
};
