import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { initialDashboardState } from './dashboard.state';
import {
  DASHBOARD_PAGE,
  DASHBOARD_URL,
  FilterParam,
  IDashboardPage,
  IDashboardParams,
  IDashboardSlicers,
} from './dashboard.interface';

export const dashboardReducer = createReducer(
  initialDashboardState,
  on(DashboardActions.resetDashboard, (state) => ({
    ...initialDashboardState,
  })),
  on(DashboardActions.getEmbedParamsSuccess, (state, { embedParams }) => ({
    ...state,
    embedParams: embedParams,
    isLoading: false,
    error: null,
  })),
  on(DashboardActions.getEmbedParamsError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(DashboardActions.setOpenDashboardFilter, (state) => ({
    ...state,
    shouldCloseDashboard: false,
  })),
  on(DashboardActions.setSelectedDashboard, (state, { dashboard }) => ({
    ...state,
    shouldCloseDashboard: false,
    activePage: setActivePage(state.pagesActivity, dashboard, null),
    selectedDashboard: {
      url: dashboard,
      title: state.dashboardParams[dashboard]?.title,
      // title: state.dashboardParams.find((param) => param.value === dashboard)?.title,
      waitingForData: isWaitingForData(
        dashboard,
        state.dashboardParams,
        state.filters,
      ),
    },
    selectedDashboardSlicers: getSelectedDashboardSlicers(
      dashboard,
      state.dashboardParams,
      state.dashboardSlicers,
      state.filters,
    ),
  })),
  on(DashboardActions.setActivePage, (state, { page }) => ({
    ...state,
    activePage: setActivePage(
      state.pagesActivity,
      state.selectedDashboard?.url,
      page,
    ),
  })),
  on(DashboardActions.setFilters, (state, { filters }) => ({
    ...state,
    shouldCloseDashboard: true,
    filters: {
      ...state.filters,
      [FilterParam.Date_Range]: filters[FilterParam.Date_Range],
      [FilterParam.Meter_Group]: filters[FilterParam.Meter_Group],
      [FilterParam.Meters_Single]: filters[FilterParam.Meters_Single],
      [FilterParam.Meters_Multiple]: filters[FilterParam.Meters_Multiple],
      [FilterParam.Tempeture_Unit]: filters[FilterParam.Tempeture_Unit],
      [FilterParam.Energy_Unit]: filters[FilterParam.Energy_Unit],
      [FilterParam.Demand_Unit]: filters[FilterParam.Demand_Unit],
      [FilterParam.Utility_Service]: filters[FilterParam.Utility_Service],
      [FilterParam.Consumer_Energy_Unit]:
        filters[FilterParam.Consumer_Energy_Unit],
    },
    selectedDashboard: {
      ...state.selectedDashboard,
      waitingForData: isWaitingForData(
        state.selectedDashboard?.url,
        state.dashboardParams,
        filters,
      ),
    },
    selectedDashboardSlicers: getSelectedDashboardSlicers(
      state.selectedDashboard?.url,
      state.dashboardParams,
      state.dashboardSlicers,
      filters,
    ),
  })),
  on(
    DashboardActions.getDistinctMetersByMeterGroupsSuccess,
    (state, { distinctMeters }) => ({
      ...state,
      distinctMetersByMeterGroups: distinctMeters,
    }),
  ),
  on(
    DashboardActions.getTotalGroupsMeterCountSuccess,
    (state, { totalGroupsMeterCount }) => ({
      ...state,
      totalGroupsMeterCount,
    }),
  ),
  on(
    DashboardActions.getTotalMeterCountSuccess,
    (state, { totalMeterCount }) => ({
      ...state,
      totalMeterCount,
    }),
  ),
);
function setActivePage(
  statePages: any,

  dashboard: DASHBOARD_URL,
  page: string | null,
): IDashboardPage {
  if (!page) return null;
  return page
    ? statePages[dashboard].pages.find((p) => p.displayName === page)
    : statePages[dashboard].pages.find((page) => page.isMain);
}

function getSelectedDashboardSlicers(
  selectedDashboard: DASHBOARD_URL,
  // dashboardParams: IDashboardParams[],
  dashboardParams: { [key in DASHBOARD_URL]: IDashboardParams },
  dashboardSlicers: { [key in FilterParam]: IDashboardSlicers },
  filters: any,
): { [key in FilterParam]: IDashboardSlicers } {
  let selectedDashboardSlicers: any = {};

  // create an array of slicers that match the selected dashboard filters, and attach the filter values to params
  const dashboardFilters = dashboardParams[selectedDashboard]?.filters;
  if (!dashboardFilters) return null;
  for (const filter of dashboardFilters) {
    const slicer = dashboardSlicers[filter];
    if (slicer) {
      let value: any;
      if (filter === FilterParam.Date_Range) {
        const [startDate, endDate] = filters[FilterParam.Date_Range];
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        value = [startDate.toISOString(), endDate.toISOString()];
      } else {
        value = filters[slicer.filterParam];
      }

      selectedDashboardSlicers[filter] = {
        ...slicer,
        params: value,
      };
    }
  }

  return selectedDashboardSlicers as {
    [key in FilterParam]: IDashboardSlicers;
  };
}

function isWaitingForData(
  selectedDashboard: DASHBOARD_URL,
  dashboardParams: { [key in DASHBOARD_URL]: IDashboardParams },
  filters: any,
): boolean {
  return (
    dashboardParams[selectedDashboard] &&
    ((dashboardParams[selectedDashboard].filters.includes(
      FilterParam.Meters_Single,
    ) &&
      filters[FilterParam.Meters_Single].length === 0) ||
      (dashboardParams[selectedDashboard].filters.includes(
        FilterParam.Meters_Multiple,
      ) &&
        filters[FilterParam.Meters_Multiple].length === 0) ||
      (dashboardParams[selectedDashboard].filters.includes(
        FilterParam.Meter_Group,
      ) &&
        filters[FilterParam.Meter_Group].length === 0))
  );
}
