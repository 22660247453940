import {
  IFilterOptions,
  IPagingRequest,
  initialPagingRequest,
  initialPagingResponse,
} from '@shared/utility/global-enums';
import {
  IMeter,
  IMetersResult,
  IReplacmentHystory,
  MeasuringPointType,
  MeterName,
  MeterNameList,
  MeterStatus,
} from './meters.interface';

import { UtilityService } from '@features/organizations/store/organizations.interface';

export interface IMeterState {
  meters: IMetersResult;
  meterNameList: MeterNameList | null;
  selectedMeter: IMeter;
  replacementHistory: IReplacmentHystory;
  pagingRequest: IPagingRequest;
  filter: {
    // MeterUID: string[];
    // MeterGroupUID: string[];
    // UtilityServiceId: UtilityService[];
    // MeasuringPointTypeId: MeasuringPointType[];
    // PowerSourceUID: string[];
    // IsVirtual: string[];
    // IsOppositeConfiguration: string[];
    // StateId: string[];
    // City: string[];
    // ChannelConfigurationUID: string[];
    // StatusID: string[];
    [key: string]: string[] | Date | null;
  };
  search: string;
  filterOptions: IFilterOptions[];
  drawersState: {
    meterEditDrawer: boolean;
    replacementEditDrawer: boolean;
    virtualMeterEditGroupsDrawer: boolean;
  };
  ValidationErrors: any;
  isNewMeter: boolean;
}

export const initialMeterState: IMeterState = {
  meters: { items: [], pagingInfo: initialPagingResponse, isLoading: true },
  meterNameList: { items: [], isLoading: true },
  selectedMeter: {
    meterUID: null,
    internalMeterID: null,
    createDate: null,
    lastConsumption: null,
    meterGeneralInfo: {
      meterName: '',
      utilityServiceID: null,
      measuringPointTypeID: null,
      timeZoneID: '',
      meterStatusID: MeterStatus.Active,
      powerSourceUID: null,
      stateID: null,
      city: '',
      zipCode: null,
      streetAddress: null,
      latitude: 0,
      longitude: 0,
      locationID: null,
      isVirtual: false,
      virtualMeterTypeID: null,
    },
    currentReplacement: {
      meterReplacementUID: null,
      externalID: null,
      serialNumber: null,
      installationDate: null,
      endDate: null,
      multiplier: 1,
      channelConfigurationUID: null,
      channels: [],
      isOppositeConfiguration: false,
      isCurrent: false,
    },
    transformerSetting: {
      transformerTypeID: null,
      nominalValueKVA: null,
      optimalLoadPercentage: 60,
      typicalPfForPvProducer: 1,
      typicalPfForConsumer: 0.85,
      overloadHoursThreshold: 2,
    },
    groupUIDs: null,
    virtualMeterChildGroups: [
      { childGroupUID: 'qwxqwxqwxqwx', metersCount: 3 },
    ],
    virtualMeterChildMeters: [
      {
        childMeterUID: 'qwxqwxqwxqwxq',
        factor: 1,
        meterName: 'Meter 1',
        measuringPointType: MeasuringPointType.Consumer,
      },
      {
        childMeterUID: 'qwxqwxqwxqwxq',
        factor: 1,
        meterName: 'Meter 2',
        measuringPointType: MeasuringPointType.Generator,
      },
    ],
  },
  replacementHistory: {
    items: [],
    selectedReplacement: {
      meterReplacementUID: null,
      externalID: null,
      serialNumber: null,
      installationDate: null,
      endDate: null,
      multiplier: 1,
      channelConfigurationUID: null,
      channels: [],
      isOppositeConfiguration: false,
      isCurrent: false,
    },
    isNewReplacement: true,
  },
  pagingRequest: initialPagingRequest,
  filter: {
    // MeterUID: [],
    // MeterGroupUID: [],
    // PowerSourceUID: [],
    // UtilityServiceId: [],
    // MeasuringPointTypeId: [],
    // IsVirtual: [],
    // IsOppositeConfiguration: [],
    // StateId: [],
    // City: [],
    // ChannelConfigurationUID: [],
    // StatusID: [],
  },
  search: '',
  filterOptions: [],
  drawersState: {
    meterEditDrawer: false,
    replacementEditDrawer: false,
    virtualMeterEditGroupsDrawer: false,
  },
  ValidationErrors: null,
  isNewMeter: false,
};
