import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IMeterGroup,
  IMeterGroupView,
} from '@features/meter-group/store/meter-group.interface';

@Injectable({
  providedIn: 'root',
})
export class MeterGroupsService {
  apiBaseUrl = '';
  endpoint = 'metergroups';

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }
  getDistinctMetersByMeterGroups(meterGroupUIDs: string[]): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/metersCount`,
      {
        meterGroupUIDs: meterGroupUIDs,
      },
    );
  }

  updateMeterGroupMetersList(
    meterGroupUID: string,
    meterUIDsToAdd: string[] = null,
    meterUIDsToRemove: string[] = null,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${meterGroupUID}/meters`,
      {
        meterUIDsToAdd: meterUIDsToAdd,
        meterUIDsToRemove: meterUIDsToRemove,
      },
    );
  }
  getMeterGroups(
    MetersUtilityService?: number,
    WithoutVirtualMeters?: boolean,
  ): Observable<IMeterGroupView[]> {
    return this.http.get<any>(`${this.apiBaseUrl}${this.endpoint}`, {
      params: new HttpParams()
        .set('MetersUtilityService', MetersUtilityService)
        .set('WithoutVirtualMeters', WithoutVirtualMeters),
    });
  }

  addMeterGroup(MeterGroupUpdated: IMeterGroup): Observable<any> {
    if (!MeterGroupUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.post<any>(`${this.apiBaseUrl}${this.endpoint}`, {
      meterGroupName: MeterGroupUpdated.meterGroupName,
      parentMeterGroupUID: MeterGroupUpdated.parentMeterGroupUID,
    });
  }

  updateMeterGroup(MeterGroupUpdated: IMeterGroup): Observable<any> {
    if (!MeterGroupUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${MeterGroupUpdated.meterGroupUID}`,
      {
        meterGroupName: MeterGroupUpdated.meterGroupName,
        parentMeterGroupUID: MeterGroupUpdated.parentMeterGroupUID,
      },
    );
  }

  deleteMeterGroup(deleteMeterGroupUID: string): Observable<any> {
    if (!deleteMeterGroupUID) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/${deleteMeterGroupUID}`,
    );
  }
}
