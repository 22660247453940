import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IDashboardState } from './dashboard.interface';

const selectDashboardState =
  createFeatureSelector<IDashboardState>('dashboard');

export const selectEmbedParams = createSelector(
  selectDashboardState,
  (state) => state.embedParams,
);

export const selectIsResultLoading = createSelector(
  selectDashboardState,
  (state) => state.isLoading,
);

export const selectFilters = createSelector(
  selectDashboardState,
  (state) => state.filters,
);

export const selectDashBoardParams = createSelector(
  selectDashboardState,
  (state) => state.dashboardParams,
);

export const selectSelectedDashboard = createSelector(
  selectDashboardState,
  (state) => state.selectedDashboard,
);

export const selectDashBoardSlicers = createSelector(
  selectDashboardState,
  (state) => state.dashboardSlicers,
);

export const selectSelectedDashboardSlicers = createSelector(
  selectDashboardState,
  (state) => state.selectedDashboardSlicers,
);

export const selectShouldCloseDashboard = createSelector(
  selectDashboardState,
  (state) => state.shouldCloseDashboard,
);

export const selectActivePage = createSelector(
  selectDashboardState,
  (state) => state.activePage,
);

export const selectDistinctMetersByMeterGroups = createSelector(
  selectDashboardState,
  (state) => state.distinctMetersByMeterGroups,
);

export const selectTotalGroupsMeterCount = createSelector(
  selectDashboardState,
  (state) => state.totalGroupsMeterCount,
);

export const selectTotalMeterCount = createSelector(
  selectDashboardState,
  (state) => state.totalMeterCount,
);

export const selectEmbedParamsError = createSelector(
  selectDashboardState,
  (state) => state.error,
);
