import {
  Configuration,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = environment.b2cPolicies;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Verbose,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //     case LogLevel.Error:
        //         console.error('msal:',message);
        //         return;
        //     case LogLevel.Info:
        //         console.info('msal:',message);
        //         return;
        //     case LogLevel.Verbose:
        //         console.debug('msal:',message);
        //         return;
        //     case LogLevel.Warning:
        //         console.warn('msal:',message);
        //         return;
        // }
      },
      piiLoggingEnabled: false,
    },
  },
};

//  const protectedResources = {
//   devAPI: {
//     getUsers: environment.apiBaseUrl + 'User/GetUsers',
//     getUserById: environment.apiBaseUrl + 'User/GetUserById',
//     getUserList: environment.apiBaseUrl + 'User/getUserList',
//     CreatePendingUser: environment.apiBaseUrl + 'User/CreatePendingUser',
/*  export const DevApiScopes = [
  'offline_access',
  'openid',
  'https://expertpowerb2c.onmicrosoft.com/xpwApi/api.scope',
];
  */
export const DevApiProtectedResourceMap: Map<string, string[]> = new Map([
  [environment.apiBaseUrl + 'users', environment.ApiScopes],
  [environment.apiBaseUrl + 'departments', environment.ApiScopes], // For all calls for Department
  [environment.apiBaseUrl + 'metergroups', environment.ApiScopes], // For all calls for Device group
  [environment.apiBaseUrl + 'meters', environment.ApiScopes], // For all calls for Device group
  [environment.apiBaseUrl + 'organizations', environment.ApiScopes], // For all calls for Organizations
  [environment.apiBaseUrl + 'location', environment.ApiScopes], // For all calls for Location
  [environment.apiBaseUrl + 'dashboard', environment.ApiScopes], // For all calls for Dashboard
  [environment.apiBaseUrl + 'fileImports', environment.ApiScopes], // For all calls for fileImports
  [environment.apiBaseWS + 'notifications', environment.ApiScopes], // For all calls for notifications
  [environment.apiBaseWS + 'scheduledReports', environment.ApiScopes], // For all calls for scheduledReports
  [environment.apiBaseUrl + 'fileManagement', environment.ApiScopes], // For all calls for fileManagement
  [environment.apiBaseUrl + 'meterData', environment.ApiScopes], // For all calls for voltage
  [environment.apiBaseUrl + 'DailyConsumptionInterval', environment.ApiScopes], // For all calls for DailyConsumptionInterval
  [environment.apiBaseUrl + 'eventRefined', environment.ApiScopes], // For all calls for eventRefined
]);

export const loginRequest = {
  scopes: [],
};
